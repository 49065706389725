<template>
    <div>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00024 1V17" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.00024 9H17.0002" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>

</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#8D8C97'
    }
  }
}
</script>
