<template>
  <div class="popup-star">
    <div class="popup-star-wrap">
      <div class="star-popup" :class="['stars-1']">
        <div class="small-container">
          <div class="star-popup-image" :class="{ 'gold-view': stars && stars === 3 }">
            <img
              v-if="stars && stars === 3"
              :src="require('@/assets/images/icons/coins-gold-style.svg')"
              width="100%" alt=""/>

            <icon-profile-coin v-if="stars && stars < 3 && stars > 0" :color="stars === 2 ? 'F4F4F4' : ''"/>
          </div>
          <div class="star-popup-content">
            <h5 class="h5-style-lg" v-html="starPopup[stars - 1].content"/>
          </div>
          <div class="star-popup-button">
            <div class="yellow-button">
              <button type="button"
                      v-text="starPopup[stars - 1].button"
                      class="btn-default"
                      @click="$emit('handleStars')"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconProfileCoin from '@/components/icons/icon-profile-coin'

export default {
  components: { iconProfileCoin },
  props: {
    stars: {
      type: Number,
      default: 1,
      required: true
    }
  },
  data () {
    return {
      isHidden: false,
      isFalse: false,
      starPopup: [
        {
          image: 'oops.svg',
          content: this.__t('star-popups.bronze.title') + '<br/>' + this.__t('star-popups.bronze.description'),
          button: this.__t('star-popups.add-to-profile')
        },
        {
          image: 'oops.svg',
          content: this.__t('star-popups.silver.title') + '<br/>' + this.__t('star-popups.silver.description'),
          button: this.__t('star-popups.add-to-profile')
        },
        {
          image: 'oops.svg',
          content: this.__t('star-popups.gold.title') + '<br/>' + this.__t('star-popups.gold.description'),
          button: this.__t('star-popups.add-to-profile')
        }
      ]
    }
  }
}
</script>
