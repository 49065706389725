<template>
  <div class="sign-up-wrap-email purple-bg py-13">
    <div class="small-container">
      <div class="sign-up-wrap-email--logo">
        <!-- <router-link :to="{name: 'Home'}">
          <img src="@/assets/images/general/wear-it-logo.svg"/>
        </router-link> -->
      </div>
      <div class="sign-up-wrap--email" v-if="!done">
        <h2 class="h2-style">Reset password</h2>
        <form @submit.prevent="handleReset" @input="validateForm">
          <!-- <div class="form-group">
            <label for="email">Email</label>
            <input type="text" class="form-control" @input="validateEmail(user.username)" :class="{ 'is-invalid': !validate.email }" required v-model="user.username" id="email" aria-describedby="username" />
            <small class="form-text invalid-feedback">Not a valid email.</small>
          </div> -->
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" :class="{ 'is-invalid': !validate.password }" @input="validatePassword(user.password)" required v-model="user.password" id="password" />
            <small class="form-text invalid-feedback">{{ __t('sign-up.not-valid-pass') }}</small>
          </div>
          <div class="form-group">
            <label for="confirm_password">Confirm Password</label>
            <input type="password" class="form-control" :class="{ 'is-invalid': !validate.password_confirmation }" @input="validatePassword(user.password_confirmation, true)" required v-model="user.password_confirmation" id="confirm_password" />
            <small class="form-text invalid-feedback">{{ __t('password-not-match') }}</small>
          </div>
          <div class="yellow-button">
            <button type="submit" class="btn-default" :disabled="!valid">Submit</button>
          </div>
        </form>
      </div>
      <div class="sign-up-wrap--email" v-else>
        <p v-text="message"></p>
      </div>
      <!-- <h5 class="h5-style-lg" v-if="!done">Dont’ have an account? Create
        <router-link :to="{ name: 'SignUpEmail' }">here</router-link>
        .
      </h5>
      <div class="yellow-button" v-else>
        <router-link class="btn-default" :to="{ name: 'Login' }">Login</router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      valid: false,
      done: false,
      message: '',
      user: {
        email: null,
        token: null,
        password: null,
        password_confirmation: null
      },
      validate: {
        email: true,
        password: true,
        token: true,
        password_confirmation: true
      }
    }
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    handleReset () {
      this.resetPassword(this.user).then((resp) => {
        this.message = resp.message
        this.done = true
      }).catch((r) => {
        this.message = r.message
        this.done = true
      })
    },
    validateEmail (email) {
      // eslint-disable-next-line
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.validate.email = re.test(email)
    },
    validatePassword (password, type = false) {
      let data = password.trim()
      if (type) {
        this.validate.password_confirmation = this.user.password.trim() === data
      } else this.validate.password = (data && data !== '' && data.length > 7)
    },
    validateForm () {
      this.valid = this.validate.token && this.validate.email && this.validate.password && this.validate.password_confirmation
    }
  },
  components: {},
  mounted () {
  },
  created () {
    // debugger
    const token = this.$route.params.token
    const email = this.$route.query.email
    if (token && email) {
      this.user.token = token
      this.user.email = email
    } else {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
