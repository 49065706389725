<template>
  <div>
    <div class="upload-wrapper-close" v-if="isProfile">
      <span @click.stop="finish">
        <img :src="closeIcon"/>
      </span>
    </div>
    <div class="upload-wrapper-photo">
      <div class="upload-btn-wrapper">
        <button class="btn" :class="{'no-border': profilePicture && profilePicture.includes('avatar')}">
          <span class="cover" v-if="!profilePicture">
            <img :src="require('@/assets/images/icons/download-white.svg')" width="100%" alt=""/>
            {{ __t('sign-up-avatar.upload-photo') }}
          </span>
          <span class="image" v-else>
            <img :src="profilePicture" alt=""/>
          </span>
        </button>
        <input type="file" accept="image/png,image/jpeg,image/JPEG,image/jpg" id="fileUpload" ref="fileUpload"
               @change="handleUpload" name="myfile"/>
      </div>
      <div class="avatars-popup" v-if="showAvatars && base.avatars.length > 0">
        <div class="avatars-popup-in">
          <span class="close-popup" @click.stop="showAvatars = false">
            <img :src="closeIcon"/>
          </span>
          <template v-for="(avatar, i) in base.avatars">
            <button :key="'avatar-key'+ i" @click.stop="handleAvatar(avatar)">
              <img :src="base.baseFilePath + avatar.image_path" width="100%" alt=""/>
            </button>
          </template>
        </div>
      </div>
    </div>
    <div class="alert alert-danger" role="alert" v-if="error.show" v-text="error.msg"></div>
    <div class="upload-wrapper-buttons">
      <a v-if="isMobile" class="btn-default btn-classic cursor-pointer" @click="takePic">
        {{ __t('sign-up-avatar.take-photo') }}
      </a>
      <a class="btn-default btn-classic cursor-pointer"
         @click="showAvatars = true">{{ __t('sign-up-avatar.choose-avatar') }}</a>
      <a @click="handleFacebook" class="btn-default btn-classic cursor-pointer"
         v-if="!isProfile && user.social_id">
        {{ __t('sign-up-avatar.facebook-photo') }}
      </a>
    </div>
    <div class="yellow-button" v-if="!isProfile">
      <button type="submit" class="btn-default" :disabled="!valid" @click.prevent="finish()"
              v-text="!isProfile ? __t('sign-up-avatar.submit') : 'DONE'"></button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
// import PopupWhite from '@/components/modals/PopupWhite.vue'
const smallDevice = window.matchMedia('(max-width: 767px)')
export default {
  components: {
    // PopupWhite
  },
  props: {
    isProfile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hide: false,
      valid: false,
      showAvatars: false,
      popupW: {
        title: 'Rating needed',
        text: 'X minutes left to rate USERNAME The community wants your opinion!',
        buttons: {
          text: 'Let’s do it'
        }
      },
      error: {
        show: false,
        msg: ''
      },
      profilePicture: null
    }
  },
  computed: {
    ...mapState('auth', ['user', 'avatars', 'base']),
    isMobile () {
      return smallDevice.matches
    },
    closeIcon () {
      return require('@/assets/images/icons/close.svg')
    }
  },
  methods: {
    ...mapActions('auth', ['updateAvatar', 'updateProfileImage', 'updateProfileFacebook']),
    finish () {
      !this.isProfile
        ? this.$router.push({ name: 'UpdateProfile' })
        : this.$emit('handleProfileModal')
    },
    handleMyPopup (val) {
      this.hide = val
    },
    handleUpload (e) {
      // let files = e.target.files || e.dataTransfer.files
      let formData = new FormData()
      formData.append('image', this.$refs.fileUpload.files[0])
      this.updateProfileImage(formData).then(resp => {
        this.profilePicture = resp.profile_image
        this.valid = true
      }).catch(err => {
        this.error.show = true
        this.error.msg = err.message || 'Please try again.'
      })
    },
    handleAvatar (avatar) {
      this.updateAvatar({ avatar_id: avatar.id }).then((resp) => {
        this.profilePicture = this.base.baseFilePath + avatar.image_path
        this.valid = true
        this.showAvatars = false
      }).catch((err) => {
        this.error.show = true
        this.error.msg = err.message || 'Please try again.'
      })
    },
    handleFacebook () {
      this.updateProfileFacebook({ fb_image: 'https://graph.facebook.com/v3.3/' + this.user.social_id + '/picture?width=600' }).then((resp) => {
        this.profilePicture = 'https://graph.facebook.com/v3.3/' + this.user.social_id + '/picture?width=600'
        this.valid = true
      }).catch((err) => {
        this.error.show = true
        this.error.msg = err.message || 'Please try again.'
      })
    },
    takePic () {
      document.getElementById('fileUpload').click()
    }
  }
}
</script>
