<template>
  <div class="settings-wrapper purple-bg">
    <div class="settings-mobile__title" v-if="isMobile">
      <div class="settings-mobile__title__back-arrow">
        <router-link :to="{ name: 'Settings'}">
          <img src="@/assets/images/icons/arrow-left.svg"/>
        </router-link>
      </div>
      <h5 class="h5-style" v-text="currentChild.title"></h5>
      <div v-if="!isMobile || (isMobile && currentChild && currentChild.slug === 'account')"
           class="settings-mobile__account-edit">
        <h5 class="h5-style cursor-pointer" v-if="!canEdit && !confirmChanges"
            @click="canEdit = true; alert.show = false">Edit</h5>
        <h5 class="h5-style cursor-pointer" v-else-if="canEdit && !confirmChanges" @click="handleChanges">Save</h5>
      </div>
    </div>
    <div class="container">
      <div class="settings-account">
        <div class="padding-div" v-if="!isMobile || (isMobile && currentChild && currentChild.slug === 'account')">
          <div v-if="!isMobile" class="settings-account__headings">
            <h2 class="h1-style">Account</h2>
            <div class="settings-account__headings__edit-save">
              <h5 v-if="!canEdit && !confirmChanges" class="h5-style cursor-pointer"
                  @click="canEdit = true; alert.show = false"
                  v-text="'Edit'"></h5>
              <h5 v-else-if="canEdit && !confirmChanges" @click="handleChanges" class="h5-style cursor-pointer"
                  v-text="'Save'"></h5>
            </div>
          </div>

          <div class="personal-details">
            <h3 v-if="!isMobile">Personal Details</h3>
            <h5 class="h5-style-lg">{{ __t('account.details-description') }}</h5>
            <form class="mt-4" @input="changes = true">
              <div class="information-group">
                <label for="email">Email*</label>
                <input type="email"
                       id="email"
                       name="email"
                       class="information-group-input"
                       :disabled="!canEdit"
                       v-model="userData.email"/>
              </div>
              <div class="information-group">
                <label for="phone">Phone*</label>
                <input type="text"
                       id="phone"
                       name="phone"
                       class="information-group-input"
                       :disabled="!canEdit"
                       v-model="userData.phone">
              </div>
              <div class="information-group mb-2">
                <label for="username">Username</label>
                <input type="text"
                       id="username"
                       name="username"
                       maxlength="36"
                       class="information-group-input"
                       :disabled="!canEdit"
                       v-model="userData.username"/>
              </div>
              <h5 class="h5-style-lg">{{ __t('account.details-username-limit') }}</h5>
              <div class="information-group">
                <label for="subline">Subline</label>
                <input type="text"
                       id="subline"
                       name="subline"
                       class="information-group-input"
                       :disabled="!canEdit"
                       v-model="userData.subline"/>
              </div>
              <div class="information-group form-group gender">
                <label>Gender</label>
                <div class="information-group-select-wrapper">
                  <multiselect
                    placeholder="Gender"
                    label="text"
                    track-by="id"
                    v-model="userData.gender"
                    :options="genderList"
                    :disabled="!canEdit"
                    :allow-empty="false"
                    :show-labels="false"/>
                </div>
              </div>
              <div class="alert" :class="['alert-' + alert.type]" role="alert" v-if="alert.show"
                   v-text="alert.msg"></div>
              <div class="information-group" v-if="canEdit && !confirmationCodeValidation">
                <small>{{ __t('account.info-for-verification-code') }}</small>
              </div>

              <div class="verify-number" v-if="confirmationCodeValidation">
                <h2 class="h1-style">{{ __t('account.verification-code') }}</h2>
                <h5 class="h5-style">{{ __t('account.enter-verification-code') }}</h5>
                <div class="verification-code">
                  <input ref="fisrt"
                         @input="updateField"
                         type="number"
                         v-model="vcode.first"
                         class="input-code"
                         name="first">
                  <input ref="sec"
                         @input="updateField"
                         type="number"
                         v-model="vcode.sec"
                         class="input-code"
                         name="sec">
                  <input ref="third"
                         @input="updateField"
                         type="number"
                         v-model="vcode.third"
                         class="input-code"
                         name="third">
                  <input ref="fourth"
                         @input="updateField"
                         type="number"
                         v-model="vcode.fourth"
                         class="input-code"
                         name="fourth">
                </div>
                <div class="code-not-received">
                  <img src="@/assets/images/icons/info.svg"/>
                  <small>
                    {{ __t('account.no-code-retry') }}
                    <a class="cursor-pointer" @click="getVerificationCode({ email: userData.email })">here</a>.
                  </small>
                </div>
              </div>

              <div class="yellow-button mt-5" v-if="canEdit">
                <button type="button" class="btn-default" @click="handleChanges">{{ __t('account.save') }}</button>
              </div>
            </form>
          </div>
        </div>

        <div class="help-and-safety padding-div"
             v-if="!isMobile || (isMobile && currentChild && currentChild.slug === 'help-and-safety')">
          <h3 v-if="!isMobile" v-text="__t('settings.help-safety')"></h3>
          <template v-for="(item, i) in base.accountNav.helpData">
            <accordion :key="'key-acc-' + i" :item="item" :isMobile="isMobile"></accordion>
          </template>
        </div>

        <div class="legal padding-div" v-if="!isMobile || (isMobile && currentChild && currentChild.slug === 'legal')">
          <h3 v-if="!isMobile" v-text="'Legal'"></h3>
          <template v-for="(item, i) in base.accountNav.legalData">
            <accordion :key="'key-acc-item-' + i" :item="item" :isMobile="isMobile"></accordion>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions, mapState } from 'vuex'
import Accordion from '@/components/accordions/Accordion'

const smallDevice = window.matchMedia('(max-width: 767px)')

export default {
  components: {
    Accordion,
    Multiselect
  },
  data () {
    return {
      canEdit: false,
      changes: false,
      confirmChanges: false,
      currentChild: {
        title: 'Account',
        slug: 'account'
      },
      userData: null,
      vcode: {
        first: null,
        sec: null,
        third: null,
        fourth: null
      },
      alert: {
        type: 'success',
        show: false,
        msg: ''
      },
      gender: null,
      confirmationCodeValidation: false
    }
  },
  computed: {
    ...mapState('auth', ['user', 'base']),
    isMobile () {
      return smallDevice.matches
    },
    genderList () {
      let list = []

      for (let i in this.base.profileGender) {
        list.push({
          id: parseInt(i),
          text: this.base.profileGender[i]
        })
      }

      return list
    }
  },
  methods: {
    ...mapActions('auth', ['getVerificationCode', 'updateUser']),
    handleChanges () {
      let needVerificationCode = this.user.email !== this.userData.email

      let hasSimpleChanges = (
        this.user.phone !== this.userData.phone ||
        this.user.subline !== this.userData.subline ||
        this.user.gender !== this.userData.gender.id ||
        this.user.username !== this.userData.username
      )

      this.canEdit = false

      if (hasSimpleChanges || needVerificationCode) {
        if (needVerificationCode) {
          this.getVerificationCode({ email: this.userData.email })
          this.confirmationCodeValidation = true
        } else {
          this.submitUser()
        }
      }
    },
    subpage () {
      return this.$route.params?.slug
    },
    submitUser () {
      let submitData = {
        email: this.userData.email,
        phone: this.userData.phone,
        username: this.userData.username,
        subline: this.userData.subline,
        gender: this.userData.gender.id,
        verification_code: this.userData.verification_code
      }
      this.updateUser(submitData).then(resp => {
        if (parseInt(resp.status) === 201) {
          this.canEdit = false
          this.changes = false
          this.confirmationCodeValidation = false
          this.alert.type = 'success'
          this.alert.msg = this.__t('profile.updated')
          this.alert.show = true
          setTimeout(() => {
            this.alert = {
              show: false,
              type: 'success',
              msg: ''
            }
          }, 4000)
        } else {
          this.alert.type = 'danger'
          this.alert.msg = resp.data
          this.alert.show = true
        }
      }).catch((resp) => {
        this.alert.type = 'danger'
        this.alert.msg = resp
        this.alert.show = true
      })
    },
    updateField (e) {
      if (!e.target.value || parseInt(e.target.value) < 0) return
      this.vcode[e.target.name].padStart(1, 0)
      switch (e.target.name) {
        case 'first':
          this.$refs['sec'].focus()
          break
        case 'sec':
          this.$refs['third'].focus()
          break
        case 'third':
          this.$refs['fourth'].focus()
          break
        case 'fourth':
          if (this.vcode['first'] && this.vcode['sec'] && this.vcode['third']) {
            this.userData = {
              ...this.userData,
              verification_code: this.vcode['first'] + this.vcode['sec'] + this.vcode['third'] + this.vcode['fourth']
            }
            this.submitUser()
          }
          break
      }
    }

  },
  created () {
    this.userData = {
      email: this.user.email,
      phone: this.user.phone,
      username: this.user.username,
      subline: this.user.subline,
      gender: {
        id: parseInt(this.user.gender),
        text: this.base.profileGender[this.user.gender]
      }
    }

    if (this.isMobile) {
      this.currentChild = {
        title: (this.subpage() === 'account' || !this.subpage()) ? 'Account' : (this.subpage() === 'help-and-safety' ? 'Help & Safety' : 'Legal'),
        slug: (this.subpage() === 'account' || !this.subpage()) ? 'account' : (this.subpage() === 'help-and-safety' ? 'help-and-safety' : 'legal')
      }
      if (!this.subpage()) this.$router.replace({ name: 'SettingsAccount', params: { slug: this.currentChild.slug } })
    } else {
      if (this.subpage()) this.$router.replace({ name: 'SettingsAccount' })
    }
  }
}
</script>
