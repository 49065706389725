<template>
  <div id="app">
    <router-view/>

<!--    <cookie-law>-->
<!--      <div slot="message">-->
<!--        {{ __t('cookie.description') }}-->
<!--        <router-link class="link" to="terms">{{ __t('cookie.learn-more') }}</router-link>-->
<!--      </div>-->
<!--    </cookie-law>-->
  </div>
</template>

<script>
// import CookieLaw from 'vue-cookie-law'

export default {
  name: 'App',
  // components: { CookieLaw },
  mounted () {
    this.$cookiebot.consentBanner()
  }
}
</script>

<style lang="scss">
.Cookie {
  z-index: 1000000;

  .link{
    text-decoration: underline;
  }
  border-top: 2px solid #211F2F;
}

.Cookie__button{
  width: 100%;
  background-color: #FFFD0B !important;
  font-size: 1rem;
  height: 3.15rem;
  padding: 0.8rem;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  border: 2px solid #211F2F !important;
  text-align: center;
  font-weight: 600;
  position: relative;
  margin-bottom: 1.5rem;
  color: #211F2F !important;
}
</style>
