<template>
  <div class="sign-up-wrap-email purple-bg py-13">
    <div class="small-container">
      <div class="sign-up-wrap-email--logo">
        <a>
          <img src="@/assets/images/general/wear-it-logo.svg"/>
        </a>
      </div>
      <div class="sign-up-wrap--email text-center">
        <h2 class="h2-style" v-if="errorLogin">{{ __t('verify-email.code-expired') }}</h2>
        <h2 class="h2-style" v-else>{{ __t('verify-email.success-message') }}</h2>
      </div>
      <h5 class="h5-style-lg text-center mt-auto">
        <router-link class="btn btn-default" :to="{ name: 'Login' }">{{ __t('verify-email.login') }}</router-link>
      </h5>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      valid: false,
      errorLogin: true,
      email: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions('auth', ['emailVerify', 'resendVerification'])
  },
  components: {},
  mounted () {},
  created () {
    const data = {
      hash: this.$route.query.hash || null,
      signature: this.$route.query.signature || null
    }
    if (data.hash && data.signature) {
      this.emailVerify(data).then((resp) => {
        this.errorLogin = !resp.success
      }).catch(() => {
        this.errorLogin = true
      })
    } else this.$router.push({ name: 'Login' })
  }
}
</script>
