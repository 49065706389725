<template>
  <results-in-popup
    v-if="notification && parseInt(notification.type) === 1"
    @handlePopup="handlesPopup"/>

  <invalid-results-popup
    v-else-if="notification && parseInt(notification.type) === 2"
    @handlePopup="handlesPopup"/>

  <outfit-of-the-day-popup
    v-else-if="notification && parseInt(notification.type) === 3"
    @handlePopup="handlesPopup"/>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import notificationsStore from '@/store/modules/notifications'
import ResultsInPopup from '@/components/modals/ResultsInPopup'
import InvalidResultsPopup from '@/components/modals/InvalidResultsPopup'
import OutfitOfTheDayPopup from '@/components/modals/OutfitOfTheDayPopup'

export default {
  mixins: [
    dynamicModule('notifications', notificationsStore)
  ],
  components: {
    ResultsInPopup,
    OutfitOfTheDayPopup,
    InvalidResultsPopup
  },
  computed: {
    ...mapState('auth', ['token']),
    ...mapState('outfit', ['create', 'canCreate']),
    ...mapState('notifications', ['notification'])
  },
  methods: {
    ...mapActions('notifications', ['loadNotification', 'markAsSeenNotification']),
    syncNotification () {
      let self = this
      this.loadNotification()
      setInterval(function () {
        if (!self.notifications) {
          self.loadNotification()
        }
      }, 60000)
    },
    handlesPopup (value) {
      let self = this
      let outfitId = this.notification.outfit_id

      this.markAsSeenNotification({ id: this.notification.id, load_next: !value }).then(() => {
        value && self.$router.push({ name: 'Profile', query: { o: outfitId } })
      })
    }
  },
  mounted () {
    this.token.token && this.syncNotification()
  }
}
</script>
