<template>
  <div class="settings-wrapper purple-bg">
    <div class="settings-mobile__title" v-if="isMobile">
      <div class="settings-mobile__title__back-arrow">
        <router-link :to="{ name: 'Settings'}">
          <img src="@/assets/images/icons/arrow-left.svg"/>
        </router-link>
      </div>
      <h5 class="h5-style" v-text="currentChild.title"></h5>
    </div>
    <div class="container">
      <div class="settings-manage">
        <h2 v-if="!isMobile" class="h1-style">Settings</h2>
        <div v-if="!isMobile || (isMobile && currentChild && currentChild.slug === 'notifications')"
             class="notifications settings-manage-group">
          <h3 v-if="!isMobile" class="h3-style mb-4">Notifications</h3>
          <div class="sign-in-group">
            <h4 class="h4-style-lg">Emails</h4>
            <div class="toggle-group">
              <div class="toggle-wrap">
                <input class="toggle toggle-switch" @change="handleChange" v-model="emails" name="emails"
                       id="switch2" type="checkbox"/>
                <label class="toggle-btn" for="switch2"></label>
              </div>
            </div>
          </div>
          <div class="sign-in-group">
            <h4 class="h4-style-lg">Push notifications</h4>
            <div class="toggle-group">
              <div class="toggle-wrap">
                <input class="toggle toggle-switch" @change="handleChange" v-model="push"
                       name="pushNotifications" id="switch3" type="checkbox"/>
                <label class="toggle-btn" for="switch3"></label>
              </div>
            </div>
          </div>
          <div class="sign-in-group">
            <div class="notification-info">
              <h4 class="h4-style-lg">{{ __t('settings.receive-updates-title') }}</h4>
              <h5 class="h5-style-lg">{{ __t('settings.receive-updates') }}</h5>
            </div>
            <div class="toggle-group">
              <div class="toggle-wrap">
                <input class="toggle toggle-switch" @change="handleChange" v-model="subscribed"
                       name="fromTheApp" id="switch4" type="checkbox"/>
                <label class="toggle-btn" for="switch4"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

const smallDevice = window.matchMedia('(max-width: 767px)')

export default {
  name: 'Settings',
  data () {
    return {
      emails: true,
      push: true,
      subscribed: false
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    isMobile () {
      return smallDevice.matches
    }
  },
  methods: {
    ...mapActions('auth', ['updateUserSettings']),
    handleChange () {
      this.updateUserSettings({
        push: this.push,
        emails: this.emails,
        subscribed: this.subscribed
      })
    },
    subpage () {
      return this.$route.params?.slug
    }
  },
  mounted () {
    this.emails = this.user.settings_email
    this.push = this.user.settings_push_notifications
  },
  created () {
    if (this.isMobile) {
      this.currentChild = {
        title: this.subpage() === 'notifications' ? 'Notifications' : 'Connected Accounts',
        slug: this.subpage() === 'notifications' ? 'notifications' : 'connected-accounts'
      }
      if (!this.subpage()) {
        this.$router.replace({ name: 'SettingsManage', params: { slug: this.currentChild.slug } })
      }
    } else {
      if (this.subpage()) this.$router.replace({ name: 'SettingsManage' })
    }
  }
}
</script>
