<template>
  <div class="wrapper">
  <Header></Header>

    <div class="container-sm">
      <h1 class="h1-style">Starting Page</h1>
      <h2 class="h2-style">Starting Page</h2>
      <h3 class="h3-style">Starting Page</h3>
      <h4 class="h4-style">Starting Page</h4>
      <h5 class="h5-style">Starting Page</h5>
      <h5 class="h5-style-lg">Starting Page</h5>
      <h6 class="h6-style">Starting Page</h6>
      <br />
      <br />
      <!-- Buttons -->
      <h3 class="h3-style">Buttons</h3>
      <a href="#" class="btn-default"> Thank You</a>
      <a href="#" class="btn-default btn-classic"> Thank You</a>

      <a href="#" class="btn-default btn-white"> Thank You</a>
      <br />
      <br />
      <!-- Tags -->
      <h3 class="h3-style">Tags</h3>
      <a href="#" class="tag">Morning</a>
      <a href="#" class="tag">Afternoon</a>
      <a href="#" class="tag">Night</a>

      <br />
      <br />
      <br />
      <!-- Toggle -->
      <h3 class="h3-style">Toggle</h3>
      <div class="toggle-group">
        <div class="toggle-wrap">
          <input class="toggle toggle-switch" id="switch" type="checkbox" checked="checked"/>
          <label class="toggle-btn" for="switch"></label>
        </div>
      </div>
      <br />
      <br />
      <!-- Forms -->
      <form>
        <div class="form-group">
          <label for="exampleInputEmail1">Email address</label>
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
          <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Password</label>
          <input type="password" class="" id="exampleInputPassword1">
        </div>
        <div class="form-group">
          <label for="exampleInputName">Name</label>
          <input type="text" class="form-control is-invalid" id="exampleInputName">
          <div class="invalid-feedback">
            Please provide a valid city.
          </div>
        </div>
        <div class="form-group checkbox">
          <input type="checkbox" name="ch1" id="ch1" value="1" >
          <label for="ch1">Checkbox</label>
        </div>
        <button type="submit" class="btn-default">Submit</button>
      </form>
      <br /> <br />

    </div>

      <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header/Header'
import Footer from '@/components/footer/Footer'
export default {
  data () {
    return {}
  },
  components: {
    Header,
    Footer
  },
  computed: {},
  methods: {},
  created () {
  }
}
</script>
