<template>
  <div class="view-wrapper notifications">
    <div class="notifications-wrapper">
      <div class="settings-mobile__title" v-if="isMobile">
        <div class="settings-mobile__title__back-arrow">
          <a @click.prevent="$router.back()">
            <img src="@/assets/images/icons/arrow-left.svg"/>
          </a>
        </div>
        <h5 class="h5-style">{{ __t('notifications.title') }}</h5>
        <div class="settings-mobile__account-edit">
          <a>
            <img :src="require('@/assets/images/icons/notifications.svg')" alt="" />
          </a>
        </div>
      </div>
      <div class="notifications-wrapper-in">
        <template v-for="(item, i) in notifications">
          <NotificationItem
            :key="'key-item-' + i"
            v-if="item.outfit.outfit_items.length"
            :topOutfit="item.outfit.outfit_items[0]"
            :notification="item">
          </NotificationItem>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NotificationItem from '@/components/notifications/NotificationItem'

const smallDevice = window.matchMedia('(max-width: 767px)')
export default {
  computed: {
    ...mapState('user', ['notifications']),
    ...mapState('auth', ['base']),
    isMobile () {
      return smallDevice.matches
    }
  },
  methods: {
    ...mapActions('user', ['searchNotifications', 'markRead']),
    getBestRate (item) {
      let iRate = 0
      let el = null
      item.outfit.outfit_items.forEach(element => {
        if (element.rating > iRate) {
          iRate = element.rating
          el = element
        }
      })
      return el
    }
  },
  components: {
    NotificationItem
  },
  created () {
    this.base.hasNotifications && this.markRead()
    this.searchNotifications()
  }
}
</script>
