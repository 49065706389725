<template>
  <div class="first-feed-view">
    <div class="small-container">
      <div class="first-feed-view-img">
        <img :src="require('@/assets/images/general/' + fvFeed.image)" width="100%" alt=""/>
      </div>
      <h4 class="h4-style" v-html="fvFeed.content"></h4>
      <div class="yellow-button">
        <button type="button" class="btn-default" @click="$emit('addOutfit', true)" v-html="fvFeed.button" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fvFeed: {
        image: 'oops.svg',
        content: this.__t('feed.empty-space'),
        button: this.__t('feed.add-outfit-now')
      }
    }
  }
}
</script>
