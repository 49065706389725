import { Http } from '@/helpers/http-helper'

const state = {
  notification: null
}
const getters = {
  getNotification: state => state.notification
}
const mutations = {
  setNotification (state, data) {
    state.notification = data
  }
}
const actions = {
  loadNotification ({ commit }) {
    return Http.get('notifications/push').then(resp => {
      resp.data && commit('setNotification', resp.data)
      return resp.data
    }).catch(err => {
      return Promise.reject(err)
    })
  },
  markAsSeenNotification ({ commit }, param) {
    return Http.post('notifications/push/seen', param).then(resp => {
      commit('setNotification', null)
      if (param.load_next) {
        setTimeout(function () {
          resp.data && commit('setNotification', resp.data)
        }, 5000)
      }

      return resp.data
    }).catch(err => {
      return Promise.reject(err)
    })
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
