<template>
  <div class="sign-up-wrap purple-bg py-13">
    <div class="small-container">
      <div class="sign-up-wrap--logo">
        <a>
          <img src="@/assets/images/general/wear-it-logo.svg"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  created () {
    if (this.$route.query?.code) {
      this.fbLogin(this.$route.query.code).then(resp => {
        resp && this.$router.push({ name: 'Home' })
      })
    } else {
      this.$router.push({ name: 'Login' })
    }
  },
  methods: {
    ...mapActions('auth', ['fbLogin'])
  }
}
</script>

<style></style>
