<template>
  <div class="popup-white">
    <div class="popup-white-in">
      <h2>{{ __t('add-app-popup.title') }}</h2>
      <h4 class="h4-style">{{ __t('add-app-popup.description') }}</h4>
      <div class="popup-white-in-buttons">
        <div class="yellow-button">
          <a href="#" class="not-now" @click.prevent="closePopup">
            {{ __t('add-app-popup.close') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closePopup () {
      this.$store.commit('auth/addAppViewed')
    }
  }
}
</script>
