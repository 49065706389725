<template>
  <div class="modal-wrapper outfit-modal" @click.self="$emit('handleGallery', false, null)">
    <div class="modal-wrapper-body outfit-modal-img">
      <div class="modal-wrapper-body-in">
        <swiper class="swiper noSwipingClass" :options="sliderOptions" ref="mySwiper" @slideChange="slideChange">
          <swiper-slide v-for="(item, i) in outfit.outfit_items" :key="'modalImg' + i">
            <div
              :style="{backgroundImage: 'url('+ $store.state.auth.base.baseFilePath + item.image_path +')', backgroundColor: item.color}"
              @click.prevent="handleNextSlide(i)">
              <div class="d-flex align-center">
                <span class="sticker" v-if="outfit.status === 1 && [1,3,4].includes(item.status)">
                  <img :src="require('@/assets/images/general/' + stickerSelect(item))" alt="">
                </span>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="delete-images-wrap">
          <a class="cursor-pointer" @click.stop="dropList = !dropList">
            <img :src="require('@/assets/images/icons/dots.svg')">
          </a>
          <div class="delete-images-wrap-img" @click.self="dropList = false" v-if="dropList">
            <ul class="delete-images-wrap-img-in">
              <li v-if="outfit.status > 0">
                <a @click.prevent="$emit('handleDelete', outfit.outfit_items[swiper.realIndex].id)"
                   class="delete cursor-pointer">
                  {{ __t('outfit.popup.delete') }}
                </a>
              </li>
              <li>
                <a @click.prevent="$emit('handleGallery', false, null)" class="go-back cursor-pointer">
                  {{ __t('outfit.popup.go-back') }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="outfit.status === 0" class="outfit-modal-label pending">
        <h5 class="h5-style">{{ __t('outfit.popup.pending') }}</h5>
      </div>
      <div v-else-if="outfit.status === 1" class="outfit-modal-label" :class="[status.class]">
        <h5 class="h5-style" v-text="status.title"></h5>
      </div>
      <div v-else-if="outfit.status === 10" class="outfit-modal-label not-rated">
        <h5 class="h5-style">{{ __t('outfit.not-rated-label') }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  props: {
    outfit: {
      type: Object,
      default: null,
      required: true
    }
  },
  data () {
    return {
      sliderOptions: {
        slidesPerView: 1,
        loop: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          renderBullet (index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom">${index + 1}</span>`
          }
        }
      },
      dropList: false,
      status: {
        class: '',
        title: ''
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
    statusClass (item) {
      this.status.title = item.status_text
      switch (item.status) {
        case 4:
          this.status = {
            class: 'winning-outfit',
            title: this.__t('outfit.popup.wining')
          }
          break
        case 3:
          this.status = {
            class: 'winning-outfit',
            title: this.__t('outfit.popup.wear-it-out')
          }
          break
        case 2:
          this.status = {
            class: 'not-sure',
            title: this.__t('outfit.popup.maybe')
          }
          break
        case 1:
          this.status = {
            class: 'try-again',
            title: this.__t('outfit.popup.next-time')
          }
          break
        default:
          this.status.class = ''
          break
      }
    },
    stickerSelect (item) {
      let img = ''
      switch (item.status) {
        case 4:
        case 3:
          img = 'wear-it-logo.svg'
          break
        // case 3:
        //   img = 'wear-it-logo.svg'
        //   break
        case 1:
          img = 'try-again-logo.svg'
          break
      }
      return img
    },
    slideChange () {
      this.outfit.status !== 0 && this.statusClass(this.outfit.outfit_items[this.swiper.realIndex])
    },
    handleNextSlide (i) {
      if (!this.swiper.isEnd) this.swiper.slideNext()
      else this.swiper.slideTo(0)
      // this.swiper.isEnd ? this.swiper.slidePrev() : (this.swiper.isBeginning ? this.swiper.this.swiper.slideNext() : '')
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.swiper.update()
      if (this.outfit.status > 0) {
        this.statusClass(this.outfit.outfit_items[0])
      }
    })
  }
}
</script>
