<template>
  <header>
    <div class="header-wrapper" v-if="!isMobile || (isMobile && !$route.meta.noHeader)">
      <div class="container">
        <div class="header-wrapper-logo">
          <router-link :to="{ name: 'Home'}">
            <img :src="require('@/assets/images/general/logo.svg')" alt="">
          </router-link>
        </div>
        <div class="header-wrapper-right-side" v-if="token.token">
          <div
          v-if="!isMobile || (isMobile && $route.name !== 'Profile')"
          class="header-wrapper-right-side-item notification"
          :class="{
            'unread': $store.state.auth.base && $store.state.auth.base.hasNotifications !== 'undefined' && $store.state.auth.base.hasNotifications
          }">
            <router-link :to="{ name: 'Notifications'}" exact-active-class="active">
              <img :src="require('@/assets/images/icons/notifications.svg')" alt="">
            </router-link>
          </div>
          <div v-if="!isMobile" class="header-wrapper-right-side-item outfit">
            <a class="cursor-pointer" @click.prevent="createOutfit">
              <img :src="require('@/assets/images/icons/plus-border.svg')" alt="">
            </a>
          </div>
          <div v-if="!isMobile || (isMobile && $route.name === 'Profile')" class="header-wrapper-right-side-item settings">
            <router-link :to="{ name: 'Settings' }" exact-active-class="active">
              <img :src="require('@/assets/images/icons/settings.svg')" alt="">
            </router-link>
          </div>
          <div v-if="!isMobile" class="header-wrapper-right-side-item profile" :class="{ 'no-border': user.profile_image && user.profile_image.includes('avatar')}">
            <router-link :to="{ name: 'Profile'}" exact-active-class="active">
              <img :src="user.profile_image" alt="">
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="header-mobile" v-if="token.token">
      <div class="container">
        <div class="header-mobile-item notification">
          <div class=" header-mobile-item-icon">
            <router-link :to="{ name: 'Home'}">
              <feed-icon></feed-icon>
              <span>Feed</span>
            </router-link>
          </div>
        </div>
        <div class="header-mobile-item outfit">
          <div class=" header-mobile-item-icon">
            <a class="cursor-pointer" @click.prevent="createOutfit">
              <plus-icon></plus-icon>
              <span>Outfit</span>
            </a>
          </div>
        </div>
        <div class="header-mobile-item profile">
          <div class="header-mobile-item-icon">
            <router-link :to="{ name: 'Profile' }">
              <img :src="user.profile_image" alt="" :class="{ 'profile-icon-mobile no-border': user.profile_image && user.profile_image.includes('avatar')}">
              <span>Profile</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FeedIcon from '@/components/icons/feed-icon'
import PlusIcon from '@/components/icons/plus-icon'

const smallDevice = window.matchMedia('(max-width: 767px)')

export default {
  name: 'Header',
  components: {
    FeedIcon,
    PlusIcon
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('auth', ['base', 'user', 'token']),
    ...mapState('outfit', ['create']),
    isMobile () {
      return smallDevice.matches
    }
  },
  methods: {
    ...mapActions('outfit', ['toggleCreate']),
    createOutfit () {
      if (!this.create) this.toggleCreate(true)
    }
  }
}
</script>
