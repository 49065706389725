<template>
    <div>
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="18" height="6" :stroke="color" stroke-width="2" stroke-linejoin="round"/>
            <rect x="1" y="10" width="18" height="6" :stroke="color" stroke-width="2" stroke-linejoin="round"/>
        </svg>
    </div>

</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#8D8C97'
    }
  }

}
</script>
