<template>
  <div class="outfit-wrapper mobile">
    <div class="popup-tpl">
      <!-- Add images -->
      <div class="settings-mobile__title">
        <div class="settings-mobile__title__back-arrow">
          <a @click.prevent="step < 2 ? toggleCreate(false) : goTo(step - 1)">
            <img :src="require('@/assets/images/icons/arrow-left.svg')"/>
          </a>
        </div>
        <h5 class="h5-style" v-text="stepTitle"></h5>
        <div v-if="allowSubmit || step < 3" class="settings-mobile__account-edit" @click="goTo(step + 1)">
          <h5 class="h5-style cursor-pointer">{{ __t('create-outfit.next-button') }}</h5>
        </div>
        <div v-else-if="!allowSubmit && step === 3" class="settings-mobile__account-edit" @click.prevent="submitOutfit">
          <h5 class="h5-style cursor-pointer">{{ __t('create-outfit.save') }}</h5>
        </div>
      </div>
      <div class="popup-tpl-inner popup-tpl-step2" v-if="step < 3">
        <div class="popup-tpl-inner-body popup-tpl-step2-body">
          <div class="inUseImages" :class="{ 'full': step > 1 }">
            <swiper
              v-if="uploadedImages.filesUrl.length > 0 && step === 1"
              ref="oSlider"
              class="swiper no-swiping mainSwiperSlider"
              :options="sliderOptions"
              @slideChange="slideChange">
              <swiper-slide v-for="(item, i) in uploadedImages.filesUrl" :key="'oc-key-' + i">
                <img :id="'mainImg-' + i" :src="item" width="100%" class="mainImg" alt=""/>
              </swiper-slide>
              <div class="swiper-pagination" v-if="step === 1" slot="pagination"></div>
            </swiper>
            <div
              v-else-if="uploadedImages.filesUrl.length > 0 && step > 1"
              class="mainSwiperSlider"
              :class="{'dropping' : dragging }">
              <template v-if="isDebugModeEnabled">
                <span class="angle">
                  Angle: {{ outfitData.images[inUse.index][2].angle.toFixed(2) }}
                </span>
                <span class="angle position">
                  Position:
                  {{ outfitData.images[inUse.index][2].real.x.toFixed(2) }} -
                  {{ outfitData.images[inUse.index][2].real.y.toFixed(2) }}
                </span>
                <span class="angle size">
                  Image: {{ outfitData.images[inUse.index][2].real.width.toFixed(2) }} -
                  {{ outfitData.images[inUse.index][2].real.height.toFixed(2) }}
                </span>
                <span class="angle csize">
                  Canvas: {{ outfitData.canvas.width.toFixed(2) }} - {{ outfitData.canvas.height.toFixed(2) }}
                </span>
              </template>
              <img :src="uploadedImages.filesUrl[inUse.index]" width="100%" class="mainImg imageRotation" alt=""
                   :style="{
                'transform': 'translate('+ outfitData.images[inUse.index][2].position.x + 'px,' +
                outfitData.images[inUse.index][2].position.y + 'px' +')' +
                'rotate(' + outfitData.images[inUse.index][2].angle + 'deg)' +
                'scale(' + outfitData.images[inUse.index][2].scale + ')'
              }"/>
              <template v-for="(av, n) in outfitData.images[inUse.index][1]">
                <div v-if="av" class="sticker" :data-index="n" :key="'c-av-key' + n"
                     :id="'stickerm-id-'+ inUse.index + n"
                     :style="{
                    // 'width':'28%',
                    'left': av.position.x + 'px',
                    'top': av.position.y + 'px',
                    'transform': 'rotate(' + av.angle + 'deg)' + 'scale(' + av.scale + ')'
                  }">
                  <img :src="base.baseFilePath + av.path" alt="">
                </div>
              </template>
              <div v-if="step === 2" id="yes-drop" class="drop-object"></div>
              <div class="imageStickers" v-if="step === 2 && !dragging">
                <swiper
                  v-if="uploadedImages.filesUrl.length > 0"
                  ref="stickerSlider"
                  class="swiper"
                  :options="stickersOptions">
                  <swiper-slide v-for="(item, i) in base.stickers" :key="'oc-key-' + i">
                    <div class="stickerItem" @click.prevent="handleMarkupSticker(item, i)">
                      <img :src="base.baseFilePath + item.image_path" width="100%" alt=""/>
                    </div>
                  </swiper-slide>
                  <!-- <div class="swiper-pagination" v-if="step === 1" slot="pagination"></div> -->
                </swiper>
              </div>
            </div>
          </div>

          <img :src="output">

          <div class="slPic" v-if="step < 2 && uploadedImages.filesUrl.length > 0">Selected Photos</div>
          <div class="previewImages" v-if="step === 1">
            <div v-for="i in 3"
                 :key="'u-img-'+ (i + 1)"
                 class="imagePlaceholder">
              <label :for="'fileImage-' + (i - 1)">
                <span class="clientImg">
                  <img :id="'img-'+ i"
                       :src="uploadedImages.filesUrl[i - 1] || require('@/assets/images/general/image-placeholder.svg')"
                       alt="" width="100%"/>
                </span>
                <input type="file" multiple accept="image/x-png,image/jpeg,image/gif"
                       :id="'fileImage-' + (i - 1)" @change="handleSingleUpload"/>
              </label>
            </div>
          </div>
          <div class="previewImages half" v-else>
            <div v-for="(item, i) in uploadedImages.filesUrl"
                 :key="'u-img-'+ (i + 1)"
                 class="imagePlaceholder">
              <label>
                <span class="clientImg" @click="handleSelection(i)">
                  <img :src="item" alt="" width="100%"/>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-tpl-inner outfitDescribe popup-tpl-step5" v-else-if="step === 3">
        <div class="popup-tpl-inner-header popup-tpl-step5-header">
          <div class="outfit-progress-bar"></div>
          <h1 class="h1-style">{{ __t('create-outfit.your-outfit') }}</h1>
        </div>
        <div class="popup-tpl-inner-body popup-tpl-step5-body">
          <div class="form-group">
            <label for="title">{{ __t('create-outfit.occasion') }} <span>{{ __t('create-outfit.occasion-info') }}</span></label>
            <input type="text" v-model="outfitData.title" class="form-control" id="title" aria-describedby="emailHelp">
          </div>
          <div class="form-group">
            <label for="message-box">{{ __t('create-outfit.description') }}
              <span>{{ __t('create-outfit.description-info') }}</span></label>
            <textarea name="subline" v-model="outfitData.description" id="message-box" rows="2"
                      class="form-control"></textarea>
          </div>
        </div>
        <div class="popup-tpl-inner-body popup-tpl-step7-body">
          <div class="time-event-side">
            <h4 class="h4-style">{{ __t('create-outfit.time') }}</h4>
            <div class="checkboxes-group">
              <div class="form-group checkbox-button" v-for="(item, i) in base.time" :key="'time-key-' + i">
                <input type="radio" name="time" :id="'time-id-' + i" v-model="outfitData.time" :value="i">
                <label :for="'time-id-' + i" v-text="item"></label>
              </div>
            </div>
          </div>

          <div class="time-event-side">
            <h4 class="h4-style">{{ __t('create-outfit.dresscode') }}</h4>
            <div class="checkboxes-group">
              <div class="form-group checkbox-button" v-for="(item, i) in base.dressCodes" :key="'dc-key-' + i">
                <input type="radio" name="dress-code" :id="'dc-id-' + i" v-model="outfitData.dress_code"
                       :value="item.id">
                <label :for="'dc-id-' + i" v-text="item.name"></label>
              </div>
            </div>
          </div>
          <div class="time-event-side">
            <h4 class="h4-style">{{ __t('create-outfit.styles') }}</h4>
            <form>
              <div class="checkboxes-group">
                <div class="form-group checkbox-button custom-check-label"
                     :class="{'active': outfitData.outfit_styles.indexOf(item.id) !== -1}"
                     v-for="(item, i) in dresscodeList" :key="'dc-key-' + i">
                  <label @click.self="handleStyles(item.id)">
                    {{ item.name }}
                    <span @click="handleStyles(item.id)" class="remove"
                          v-if="outfitData.outfit_styles.indexOf(item.id) !== -1">
                      <img :src="require('@/assets/images/icons/close.svg')" alt="">
                    </span>
                  </label>
                </div>
                <div class="form-group checkbox-button custom-check-label"
                     v-if="base.outfitStyles.length - 2 > 0 && !moreDresscode">
                  <label @click.self="moreDresscode = true">
                    +{{ base.outfitStyles.length - 2 }}
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div class="time-event-side">
            <h4 class="h4-style">{{ __t('create-outfit.step5-title') }}</h4>
            <form>
              <div class="checkboxes-group">
                <div class="form-group checkbox-button custom-check-label"
                     :class="{'active': outfitData.event_types.indexOf(item.id) !== -1}"
                     v-for="(item, i) in eventsList" :key="'et-key-' + i">
                  <label @click.self="handleEvents(item.id)">
                    {{ item.name }}
                    <span @click="handleEvents(item.id)" class="remove"
                          v-if="outfitData.event_types.indexOf(item.id) !== -1">
                      <img :src="require('@/assets/images/icons/close.svg')" alt="">
                    </span>
                  </label>
                </div>
                <div class="form-group checkbox-button custom-check-label"
                     v-if="base.eventTypes.length - 2 > 0 && !moreEvents">
                  <label @click.self="moreEvents = true">
                    +{{ base.eventTypes.length - 2 }}
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="popup-tpl-inner-header popup-tpl-step5-header">
          <div class="outfit-progress-bar"></div>
          <h1 class="h1-style">{{ __t('create-outfit.step4-title') }}</h1>
        </div>
        <div class="popup-tpl-inner-body popup-tpl-step6-body">
          <div class="gender-side">
            <h4 class="h4-style">{{ __t('create-outfit.gender') }}</h4>
            <div class="form-group checkbox" v-for="(item, i) in base.gender" :key="'s-key-' + i">
              <input type="radio" v-model="outfitData.gender" name="gender" :id="'sex-id-' + i" :value="i">
              <label :for="'sex-id-' + i" v-text="item"/>
            </div>
          </div>
          <div class="age-side">
            <h4 class="h4-style">{{ __t('create-outfit.age') }}</h4>
            <div class="form-group checkbox" v-for="(item, i) in base.ageRange" :key="'a-key-' + i">
              <input type="radio" v-model="outfitData.age_range" name="ageRange" :id="'age-id-'+ i" :value="i">
              <label :for="'age-id-' + i" v-text="item"></label>
            </div>
          </div>
        </div>
        <div class="popup-tpl-inner-footer popup-tpl-step5-footer">
          <div class="yellow-button">
            <button v-if="lock" type="button" class="btn-default">{{ __t('create-outfit.saving') }}</button>
            <button v-else type="button" class="btn-default" :disabled="allowSubmit"
                    @click.prevent="submitOutfit" v-text="__t('create-outfit.save')"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import interact from 'interactjs'
import { mapActions, mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      output: null,
      isDebugModeEnabled: false,
      canvasWidth: 0,
      canvasHeight: 0,
      angle: 0,
      step: 1,
      lock: false,
      value: { img: '../../assets/images/icons/sticker-1.svg' },
      outfitData: {
        title: '',
        description: '',
        gender: null,
        age_range: null,
        time: null,
        images: [],
        canvas: {
          width: 0,
          height: 0
        },
        event_types: [],
        outfit_styles: [],
        dress_code: null
      },
      uploadedImages: {
        filesUrl: [],
        files: [],
        index: [],
        forUpload: []
      },
      inUse: {
        index: null,
        stickers: []
      },
      sliderOptions: {
        slidesPerView: 1,
        autoHeight: false,
        // allowTouchMove: false,
        noSwiping: true,
        noSwipingClass: 'no-swiping',
        loop: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          renderBullet (index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom">${index + 1}</span>`
          }
        }
      },
      stickersOptions: {
        slidesPerView: 3.8,
        autoHeight: false,
        centeredSlides: true,
        loop: false,
        slideToClickedSlide: true
      },
      stepTitle: this.__t('create-outfit.step1-title'),
      moreDresscode: false,
      moreEvents: false,
      dragging: false,
      dragIndex: null
    }
  },
  computed: {
    ...mapState('auth', ['base']),
    swiper () {
      return this.$refs.oSlider.$swiper
    },
    stickerSwiper () {
      return this.$refs.stickerSlider.$swiper
    },
    dresscodeList () {
      return this.moreDresscode ? this.base.outfitStyles : this.base.outfitStyles.slice(0, 2)
    },
    eventsList () {
      return this.moreEvents ? this.base.eventTypes : this.base.eventTypes.slice(0, 2)
    },
    allowSubmit () {
      return !this.outfitData.title ||
        !this.outfitData.description ||
        this.outfitData.age_range === null ||
        this.outfitData.gender === null ||
        !this.outfitData.time ||
        !this.outfitData.dress_code ||
        this.outfitData.outfit_styles.length < 1 ||
        this.outfitData.event_types.length < 1
    }
  },
  methods: {
    ...mapActions('outfit', ['createOutfit', 'toggleCreate']),
    customLabel ({ title, desc }) {
      return `${title} – ${desc}`
    },
    handleSingleUpload (e) {
      let index = e.target.id.split('-')[1]
      let files = e.target.files
      let realIndex = this.uploadedImages.filesUrl[index] ? index : this.uploadedImages.filesUrl.length

      if (files.length > 0) {
        for (let i = 0; i < (files.length < 4 ? files.length : 3); i++) {
          let imgData = {
            angle: 0,
            scale: 1,
            position: {
              x: 0,
              y: 0
            },
            real: {
              x: 0,
              y: 0,
              width: 0,
              height: 0,
              canvasWidth: 0,
              canvasHeight: 0
            }
          }

          this.$set(this.outfitData.images, files.length < 2 ? realIndex : i, [files[i], [], imgData])

          this.$set(this.uploadedImages.filesUrl, files.length < 2 ? realIndex : i, URL.createObjectURL(files[i]))
          URL.revokeObjectURL(files[i])
          this.inUse.index = 0

          this.updateImagePosition(i + realIndex)
        }
      }

      let parentBoundingRect = document.getElementsByClassName('inUseImages')[0].getBoundingClientRect()
      this.outfitData.canvas.width = parentBoundingRect.width
      this.outfitData.canvas.height = parentBoundingRect.height

      this.$nextTick(() => {
        this.swiper.update()
        this.swiper.slideTo(files.length < 2 ? realIndex : 0)
        this.inUse.index = files.length < 2 ? realIndex : 0
      })
    },
    goTo (step) {
      switch (step) {
        case 1:
          this.step = 1
          this.stepTitle = 'New Outfit'
          break
        case 2:
          this.step = this.uploadedImages.filesUrl.length > 0 ? step : 1
          this.stepTitle = this.uploadedImages.filesUrl.length > 0 ? this.__t('create-outfit.step2-title') : this.__t('create-outfit.step1-title')
          this.step === 2 && setTimeout(() => {
            this.inUse.index = 0
            this.handleImageRotation()
          }, 500)
          break
        case 3:
          this.step = this.outfitData.images.length > 0 ? step : 1
          this.stepTitle = this.outfitData.images.length > 0 ? this.__t('create-outfit.step3-title') : this.__t('create-outfit.step1-title')
          if (this.outfitData.images.length < 1) {
            this.inUse = {
              index: null,
              avatars: []
            }
            this.step = 1
          }
          break
        case 4:
          break
      }
    },
    handleChecker (i) {
      let index = this.uploadedImages.index.indexOf(i)
      return index !== -1 ? (index + 1) : ''
    },
    handleMarkupSticker (item, ind) {
      let self = this

      let current = this.outfitData.images[this.inUse.index]
      let aindex = current[1].push({
        id: item.id,
        path: item.image_path,
        angle: 0,
        scale: 1,
        position: {
          x: 0,
          y: 0
        },
        real: {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        }
      })
      this.$set(this.outfitData.images, this.inUse.index, current)
      this.$nextTick(() => {
        this.$set(this.outfitData.images, this.inUse.index, current)
        let stickerImage = document.querySelector('#stickerm-id-' + self.inUse.index + (aindex - 1))
        let angleScale = {
          angle: 0,
          scale: 1
        }
        setTimeout(function () {
          self.outfitData.images[self.inUse.index][1][aindex - 1].real.width = stickerImage.getBoundingClientRect().width - 20
          self.outfitData.images[self.inUse.index][1][aindex - 1].real.height = stickerImage.getBoundingClientRect().height - 20
        }, 500)

        interact('.drop-object').dropzone({
          accept: '.sticker',
          overlap: 0.3,
          ondrop: function (event) {
            self.$nextTick(() => {
              self.outfitData.images[self.inUse.index][1][self.dragIndex] = null
            })
          },
          ondropdeactivate: function (event) {
            self.$nextTick(() => {
              self.dragging = false
            })
          }
        })

        interact('#stickerm-id-' + self.inUse.index + (aindex - 1)).gesturable({
          listeners: {
            start (event) {
              angleScale.angle -= event.angle
            },
            move (event) {
              self.outfitData.images[self.inUse.index][1][aindex - 1].angle = event.angle + angleScale.angle
              self.outfitData.images[self.inUse.index][1][aindex - 1].scale = event.scale * angleScale.scale
              self.outfitData.images[self.inUse.index][1][aindex - 1].position.x += event.dx
              self.outfitData.images[self.inUse.index][1][aindex - 1].position.y += event.dy

              self.outfitData.images[self.inUse.index][1][aindex - 1].real.x = stickerImage.getBoundingClientRect().x + 10
              self.outfitData.images[self.inUse.index][1][aindex - 1].real.y = stickerImage.getBoundingClientRect().y - 34
            },
            end (event) {
              angleScale.angle = angleScale.angle + event.angle
              angleScale.scale = angleScale.scale * event.scale
            }
          }
        }).draggable({
          listeners: {
            start (event) {
              self.dragging = true
              event.target.classList.add('canDrag')
              self.dragIndex = parseInt(event.target.getAttribute('data-index'))
            },
            move (event) {
              self.outfitData.images[self.inUse.index][1][aindex - 1].position.x += event.dx
              self.outfitData.images[self.inUse.index][1][aindex - 1].position.y += event.dy

              self.outfitData.images[self.inUse.index][1][aindex - 1].real.x = stickerImage.getBoundingClientRect().x + 10
              self.outfitData.images[self.inUse.index][1][aindex - 1].real.y = stickerImage.getBoundingClientRect().y - 34
            },
            end (event) {
              event.target.classList.remove('canDrag')
            }
          }
        })
      })
    },
    handleEvents (id) {
      let index = this.outfitData.event_types.indexOf(id)
      if (index === -1) {
        if (this.outfitData.event_types.length < 4) {
          this.outfitData.event_types.push(id)
        }
      } else {
        this.outfitData.event_types.splice(index, 1)
      }
    },
    handleStyles (id) {
      let index = this.outfitData.outfit_styles.indexOf(id)
      if (index === -1) {
        if (this.outfitData.outfit_styles.length < 4) {
          this.outfitData.outfit_styles.push(id)
        }
      } else {
        this.outfitData.outfit_styles.splice(index, 1)
      }
    },
    submitOutfit () {
      if (!this.lock) {
        this.lock = true
        let formData = new FormData()
        for (let item in this.outfitData) {
          switch (item) {
            case 'images':
              for (let i = 0; i < this.outfitData.images.length; i++) {
                formData.append('images[' + i + '][0]', this.outfitData.images[i][0])
                formData.append('images[' + i + '][1]', JSON.stringify(this.outfitData.images[i][1]))
                formData.append('images[' + i + '][2]', JSON.stringify(this.outfitData.images[i][2]))
              }
              break
            case 'outfit_styles':
            case 'event_types':
              for (let i = 0; i < this.outfitData[item].length; i++) {
                formData.append(item + '[' + i + ']', this.outfitData[item][i])
              }
              break
            default:
              formData.append(item, this.outfitData[item])
              break
          }
        }

        this.createOutfit(formData).then(resp => {
          this.toggleCreate(false)
          this.$store.dispatch('auth/searchUser')
          if (this.$route.name === 'Profile') {
            this.$store.dispatch('outfit/getRatedOutfits')
          }
          this.lock = false
        }).catch(() => {
          this.lock = false
        })
      }
    },
    slideChange () {
      this.inUse.index = this.swiper.realIndex
    },
    handleImageRotation () {
      let self = this

      interact('.imageRotation').gesturable({
        onmove: function (event) {
          self.$set(self.outfitData.images[self.inUse.index], 2, {
            angle: self.outfitData.images[self.inUse.index][2].angle + event.da,
            scale: self.outfitData.images[self.inUse.index][2].scale + event.ds,
            position: {
              x: self.outfitData.images[self.inUse.index][2].position.x + event.dx,
              y: self.outfitData.images[self.inUse.index][2].position.y + event.dy
            },
            real: self.outfitData.images[self.inUse.index][2].real
          })

          self.updateImagePosition(self.inUse.index)
        }
      }).draggable({
        onmove: function (event) {
          self.$set(self.outfitData.images[self.inUse.index], 2, {
            angle: self.outfitData.images[self.inUse.index][2].angle,
            scale: self.outfitData.images[self.inUse.index][2].scale,
            position: {
              x: self.outfitData.images[self.inUse.index][2].position.x + event.dx,
              y: self.outfitData.images[self.inUse.index][2].position.y + event.dy
            },
            real: self.outfitData.images[self.inUse.index][2].real
          })

          self.updateImagePosition(self.inUse.index)
        }
      })

      let parentBoundingRect = document.getElementsByClassName('inUseImages')[0].getBoundingClientRect()
      this.outfitData.canvas.width = parentBoundingRect.width
      this.outfitData.canvas.height = parentBoundingRect.height

      self.updateImagePosition(self.inUse.index)
    },
    handleSelection (i) {
      this.inUse.index = i
      this.updateImagePosition(i)
    },
    updateImagePosition (index) {
      let self = this

      setTimeout(function () {
        let element = document.getElementsByClassName('mainImg')[0]

        if (document.getElementsByClassName('mainImg').length > 1) {
          element = document.getElementsByClassName('mainImg')[index]
        }

        if (element !== null) {
          let elementBoundingRect = element.getBoundingClientRect()

          if (self.outfitData.images[index][2].real.width === 0) {
            self.$set(self.outfitData.images[index][2], 'real', {
              x: elementBoundingRect.x,
              y: elementBoundingRect.y - 44,
              width: elementBoundingRect.width,
              height: elementBoundingRect.height,
              canvasWidth: self.outfitData.canvas.width,
              canvasHeight: self.outfitData.canvas.height
            })
          } else {
            self.$set(self.outfitData.images[index][2], 'real', {
              x: elementBoundingRect.x,
              y: elementBoundingRect.y - 44,
              width: self.outfitData.images[index][2].real.width,
              height: self.outfitData.images[index][2].real.height,
              canvasWidth: self.outfitData.images[index][2].real.canvasWidth,
              canvasHeight: self.outfitData.images[index][2].real.canvasHeight
            })
          }
        }
      }, 500)
    }
  },
  mounted () {
    this.isDebugModeEnabled = this.$route.query.debug
  }
}
</script>

<style lang="scss" scoped>
span.angle {
  color: red;
  position: fixed;
  top: 60px;
  left: 20px;
  z-index: 10000;
  font-size: 20px;
  font-weight: bold;
}

span.scale {
  top: 85px;
}

span.position {
  top: 85px
}

span.size {
  top: 110px
}

span.csize {
  top: 135px
}

</style>
