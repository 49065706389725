import { Http } from '@/helpers/http-helper'

export default {
  namespaced: true,
  state: {
    labels: []
  },
  getters: {},
  mutations: {
    setLabels (state, data) {
      state.labels = data
    }
  },
  actions: {
    loadTranslations ({ commit }) {
      return Http.get('config/translations').then(resp => {
        commit('setLabels', resp.data)
        return resp.data
      }).catch(err => Promise.reject(err))
    }
  }
}
