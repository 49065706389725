<template>
  <div class="notification-wrap-images-item" @click="handleGal">
    <div v-if="!imageLoaded"
         class="outfit-item-image-loader"
         :style="{ backgroundImage: 'url(' + require('@/assets/images/general/loader.gif') + ')' }"></div>
    <img :id="'outfit-item-image-' + item.id" :style="{visibility: imageLoaded ? 'visible': 'hidden'}"
         :src="$store.state.auth.base.baseFilePath + item.image_path" width="100%" alt=""/>

    <img class="ootd" v-if="outfit.ootd && (single && index === 0 || !single)"
         :src="require('@/assets/images/icons/ootd.svg')" alt="">

    <div class="notification-wrap-images-item-rating" v-if="outfit.status < 2"
         :class="{'pending': item.status === 0}">
      <a :class="{[rateClass]: item.status > 0}">
        <img v-if="item.status > 0" :src="require('@/assets/images/icons/diamond.svg')" alt="">
        <span v-else class="pending-dots"></span>
        <span v-text="item.status === 0 ?
        leftMinutes + ' min' :
        ((item.rating % 1) === 0 ? item.rating : item.rating.toFixed(1))"></span>
      </a>
    </div>
    <div class="notification-wrap-images-item-rating pending" v-if="outfit.status === 10">
      <a :class="{[rateClass]: item.status > 0}">
        <!-- <span class="pending-dots"></span> -->
        <img :src="require('@/assets/images/icons/diamond.svg')" alt="">
        <!-- <span>{{ __t('outfit.not-rated') }}</span> -->
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
      required: true
    },
    outfit: {
      type: Object,
      default: null,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    single: {
      type: Boolean,
      default: false
    },
    deadline: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      myClass: '',
      leftMinutes: 0,
      imageLoaded: false,
      checkInterval: null
    }
  },
  computed: {
    rateClass () {
      let cl = ''
      switch (this.item.status) {
        case 3:
        case 4:
          cl = 'winning-outfit'
          break
        case 2:
          cl = 'not-sure'
          break
        case 1:
          cl = 'try-again'
          break
        default:
          cl = ''
          break
      }
      return cl
    }
  },
  methods: {
    handleGal () {
      !this.single && this.$emit('handleGallery', true, this.index)
    },
    calculateLeftMinutes () {
      let newDate = new Date()
      let current = new Date(this.outfit.deadline)
      let timing = Math.round(((current.getTime() - newDate.getTime()) / 1000) / 60)
      if (timing < 1) {
        timing = 1
      }
      this.leftMinutes = timing
    }
  },
  mounted () {
    if (this.outfit.status === 0) {
      this.calculateLeftMinutes()
      let _self = this
      this.checkInterval = setInterval(function () {
        _self.calculateLeftMinutes()
      }, 4500)
    }

    let imageElement = document.getElementById('outfit-item-image-' + this.item.id)
    imageElement.addEventListener('load', () => {
      this.imageLoaded = true
    })
  },
  beforeDestroy () {
    this.checkInterval && clearInterval(this.checkInterval)
  }
}
</script>
