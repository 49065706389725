<template>
  <div class="view-wrapper notifications">
    <h1>Notification Setup</h1>
    <h1>User ID: {{ userId }}</h1>
    <h1 v-if="supported">Browser Supported</h1>
    <h1 v-if="!supported">Browser not supported</h1>
    <h1 v-if="permissionGranted">Permission Granted</h1>
    <template v-if="!permissionGranted">
      <h1>Permission NOT Granted</h1>
      <button @click.prevent="grantPermissions">Grant permissions</button>
    </template>
  </div>
</template>

<script>
export default {
  data () {
    return {
      userId: '',
      supported: false,
      permissionGranted: false
    }
  },
  methods: {
    grantPermissions () {
      window.OneSignal.showNativePrompt()
    }
  },
  created () {
    let self = this
    window.OneSignal.push(function () {
      window.OneSignal.init({
        appId: process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_ONESIGNAL : process.env.VUE_APP_ONESIGNAL_DEV,
        safari_web_id: 'web.com.wearitoutapp.wearitout',
        allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production'
      })

      window.OneSignal.push(['getNotificationPermission', function (permission) {
        self.supported = window.OneSignal.isPushNotificationsSupported()

        self.permissionGranted = permission === 'granted'

        window.OneSignal.getUserId(function (userId) {
          self.userId = userId
        })
      }])

      window.OneSignal.on('subscriptionChange', function (isSubscribed) {
        if (isSubscribed) {
          location.reload()
        }
      })
    })
  }
}
</script>
