<template>
  <div>
    <div class="notification-wrap">
      <div class="notification-wrap-item">
        <div class="notification-wrap-item-img">
<!--          <img class="ootd" v-if="notification.outfit.ootd" :src="require('@/assets/images/icons/ootd.svg')" alt="">-->
          <img :src="$store.state.auth.base.baseFilePath + topOutfit.image_path" width="100%" alt=""/>
        </div>
        <div class="notification-wrap-item-content">
          <div class="notification-wrap-item-content-rating" v-if="notification.type == 1">
            <a :class="{[rateClass]: topOutfit.status > 0}">
              <img :src="require('@/assets/images/icons/diamond.svg')" alt=""/>
              <span
                v-text="((topOutfit.rating % 1) === 0 ? topOutfit.rating : topOutfit.rating.toFixed(1)) || '0'"></span>
            </a>
          </div>
          <div class="notification-wrap-item-content-text">
            <h5 class="h5-style" v-text="notification.outfit.title"></h5>
            <h5 class="h5-style-lg" v-text="notification.outfit.description"></h5>
          </div>
        </div>
        <div class="notification-wrap-item-button">
          <span :class="{ 'active': show }" @click="show = !show" v-text="(show ? 'Hide' : 'Show') + ' Results'"></span>
        </div>
      </div>
      <div class="transition-notification" v-if="notification.type == 1">
        <div class="notification-wrap-item-button">
          <span :class="{ 'active': show }" @click="show = !show" v-text="(show ? 'Hide' : 'Show') + ' Results'"></span>
        </div>
        <transition name="slide">
          <div v-if="show" class="notification-wrap-images">
            <template v-for="(item, i) in notification.outfit.outfit_items">
              <outfit-image :key="'key-img-' + i"
                            :index="i"
                            :outfit="notification.outfit"
                            :single="true"
                            :image_path="item.image_path"
                            :item="item"></outfit-image>
            </template>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import OutfitImage from '@/components/outfit/OutfitImage.vue'

export default {
  props: {
    notification: {
      type: Object,
      default: null,
      required: true
    },
    topOutfit: {
      type: Object,
      default: null,
      required: true
    }
  },
  computed: {
    rateClass () {
      let cl = ''
      switch (this.topOutfit.status) {
        case 4:
        case 3:
          cl = 'winning-outfit'
          break
        case 2:
          cl = 'not-sure'
          break
        case 1:
          cl = 'try-again'
          break
        default:
          cl = ''
          break
      }
      return cl
    }
  },
  data () {
    return {
      show: false
    }
  },
  components: {
    OutfitImage
  },
  created () {
  }
}
</script>
