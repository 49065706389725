<template>
  <div class="view-wrapper page-template" :class="{ 'no-user': !$store.state.auth.token.token }" v-if="page">
    <div class="settings-mobile__title" v-if="isMobile">
      <div class="settings-mobile__title__back-arrow">
        <a @click.stop="$router.go(-1)">
          <img src="@/assets/images/icons/arrow-left.svg"/>
        </a>
      </div>
      <h5 class="h5-style" v-text="page.title">Settings</h5>
    </div>
    <div class="container">
      <div class="page-template-wrapper">
        <div class="page-template-wrapper__headings">
          <h2 class="h1-style" v-text="page.title"></h2>
        </div>
        <div v-html="page.body_text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

const smallDevice = window.matchMedia('(max-width: 767px)')
export default {
  components: {},
  data () {
    return {
      page: null
    }
  },
  computed: {
    isMobile () {
      return smallDevice.matches
    }
  },
  created () {
    // TODO: Check if the page exists from API with Slug IF NOT redirect to 404
    // this.$router.push({ name: 'NotFound' })

    // this.slug = this.$route.params.slug
    this.$route.params.slug ? this.getPage(this.$route.params.slug).then(resp => {
      this.page = resp

      setTimeout(function () {
        document.querySelector('[href="opencookiebot"]').addEventListener('click', function (e) {
          e.preventDefault()
          e.stopPropagation()

          window.Cookiebot.show()
        })
      }, 1000)
    }).catch(() => {
      this.$router.push({ name: 'Home' })
    }) : this.$router.push({ name: 'Home' })
  },
  methods: {
    ...mapActions('user', ['getPage'])

  }
  // opencookiebot
}
</script>
