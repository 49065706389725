<template>
  <div class="sign-up-wrap sign-up-wrap-email quiz-view blue-bg py-13">
    <span class="stepProgress" v-if="step > 2" :class="{ 'last': step > 3 }"></span>
    <div class="small-container" v-if="step < 3">
      <div class="sign-up-wrap-email--logo">
        <a>
          <img src="@/assets/images/general/yellow-logo.svg"/>
        </a>
      </div>
      <div class="sign-up-wrap--email" v-if="step === 1">
        <h2 class="h2-style">{{ __t('sign-up-quiz.title') }}</h2>
        <div class="content-section">
          <p>{{ __t('sign-up-quiz.description-p1') }}</p>
          <p>{{ __t('sign-up-quiz.description-p2') }}</p>
          <p> </p>
        </div>
        <div class="form-group checkbox pb-3">
          <input type="checkbox" name="ch1" v-model="quiz.agree" id="before">
          <label for="before">{{ __t('sign-up-quiz.terms-checkbox') }}</label>
        </div>
        <div class="yellow-button">
          <button class="btn-default" type="button" :disabled="!quiz.agree" v-text="__t('sign-up-quiz.first_step_go')" @click.prevent="step = 2"></button>
        </div>
      </div>
      <div class="sign-up-wrap--email after-start text-center" v-else-if="step === 2">
        <h2 class="h2-style">{{ __t('sign-up-quiz.description') }}</h2>
        <div class="yellow-button">
          <button type="button" @click.prevent="handleStart(false)" class="btn-default">{{ __t('sign-up-quiz.submit-start') }}</button>
        </div>
        <button type="button" @click.prevent="handleStart(true)" class="btn skip-button">{{ __t('sign-up-quiz.skip') }}</button>
      </div>
    </div>

    <!-- Quiz view -->
    <div v-if="step === 3" class="medium-container">
      <div class="sign-up-wrap-email--logo">
        <a>
          <img src="@/assets/images/general/yellow-logo.svg"/>
        </a>
      </div>
      <div class="qusetion-image-wrap">
        <h5 class="h5-style">{{ __t('sign-up-quiz.question') }} {{ quizStep }}/{{ quizTotalStep }}</h5>
        <h2 class="h2-style">{{ filteredImages[0] && filteredImages[0].title }} or <br> {{ filteredImages[1] && filteredImages[1].title }}</h2>
        <div class="question-images pt-5" :key="'test-' + key">
          <div class="half-size" v-for="(item, i) in filteredImages" :key="'img-key-' + i">
            <div class="choose-image" :class="{ 'active': quizData.final_choice && quizData.final_choice === item.id }" @click.prevent="handleSelect(item, i)">
              <img :src="base.baseFilePath + item.image"/>
            </div>
            <h6 class="h6-style pt-3" v-text="item.title"></h6>
          </div>
        </div>
      </div>
    </div>
    <!-- ttttt -->
    <div v-else-if="step === 4" class="medium-container generating-feed">
      <div class="sign-up-wrap-email--logo">
        <img src="@/assets/images/general/yellow-logo.svg"/>
        <!-- <a href="#"></a> -->
      </div>
      <div class="sign-up-wrap--logo generating-feed-image">
        <img src="@/assets/images/general/Group.svg"/>
        <!-- <a href="#"></a> -->
      </div>
      <h5 class="h5-style text-center">{{ __t('sign-up-quiz.generating-feed') }}</h5>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  components: {},
  data () {
    return {
      step: 1,
      quizStep: 1,
      quizTotalStep: 1,
      quiz: {
        agree: false
      },
      key: 1,
      images: [],
      filteredImages: [],
      removedImages: [],
      mainSelection: null,
      quizData: {
        type: null,
        choices: [],
        final_choice: null
      }
    }
  },
  computed: {
    ...mapState('auth', ['user', 'base'])
  },
  methods: {
    ...mapActions('auth', ['updateQuiz', 'getQuiz']),
    handleStart (val) {
      let self = this
      this.quizData.type = val ? 2 : 1
      if (!val) {
        const data = {
          gender: self.user.gender,
          is_skip: 0
        }
        this.getQuiz(data).then(resp => {
          this.images = resp.images
          this.quizTotalStep = resp.steps
          this.filteredImages = this.images.slice(0, 2)
          this.images.splice(0, 2)
          this.step++
        })
      } else {
        let data = {
          final_choice: null,
          type: this.quizData.type
        }
        this.updateQuiz(data).then(resp => {
          resp && this.$router.push({ name: 'Home' })
        })
      }
    },
    handleSelect (item, i) {
      let index = i === 0 ? 1 : 0
      this.quizData.final_choice = item.id
      if (this.quizData.choices.indexOf(item.id) === -1) this.quizData.choices.push(item.id)
      this.removedImages.push(this.filteredImages[index].id)
      if (this.images.length > 0) {
        this.filteredImages[index] = this.images[0]
        this.quizStep++
        this.images.splice(0, 1)
        this.key++
      } else {
        this.step = 4
        setTimeout(() => {
          this.updateQuiz(this.quizData).then(resp => {
            resp && this.$router.push({ name: 'Home' })
          })
        }, 2000)
      }
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>
