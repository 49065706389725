<template>
  <div class="outfit-wrapper" @click.self="toggleCreate(false)">
    <div class="popup-tpl">
      <!-- Add images -->
      <div class="popup-tpl-step1" v-if="step === 1">
        <div class="popup-tpl-step1-header">
          <div class="outfit-progress-bar"></div>
          <h1 class="h1-style">{{ __t('create-outfit.step1-title') }}</h1>
        </div>
        <label for="file-input">
          <input
            id="file-input"
            class="btn-default"
            accept="image/png,image/jpeg,image/JPEG,image/jpg"
            type="file"
            multiple
            @change="handleSingleUpload">
          <div class="popup-tpl-step1-body">
            <img :src="require('@/assets/images/general/upload-image-white.svg')" alt=""/>
            <h2 class="h2-style">{{ __t('create-outfit.drag-pictures') }}</h2>
          </div>
          <div class="popup-tpl-step1-footer">
            <div class="yellow-button">
              <button class="btn-default">{{ __t('create-outfit.select-pictures') }}</button>
            </div>
          </div>
        </label>
      </div>
      <!-- Added images select -->
      <div class="popup-tpl-step2" v-else-if="step === 2">
        <div class="popup-tpl-step2-header">
          <div class="outfit-progress-bar"></div>
          <h1 class="h1-style">{{ __t('create-outfit.step2-title') }}</h1>
          <button type="button" class="btn back-button" @click.stop="goTo(step - 1)">{{
              __t('create-outfit.back-button')
            }}
          </button>
        </div>
        <div class="popup-tpl-step2-body">
          <div class="previewImages">
            <div v-for="i in 3"
                 :key="'u-img-'+ (i + 1)"
                 class="imagePlaceholder">
              <label :for="'fileImage-' + (i - 1)">
                <span class="checker" v-text="i + 1"></span>
                <img :src="require('@/assets/images/general/image-placeholder.svg')" alt="" width="100%"/>
                <span v-if="uploadedImages.filesUrl[i - 1]" class="clientImg flat">
                  <img :src="uploadedImages.filesUrl[i - 1]" alt="" width="100%"/>
                </span>
                <input
                  type="file"
                  accept="image/png,image/jpeg,image/JPEG,image/jpg"
                  :id="'fileImage-' + (i - 1)"
                  @change="handleSingleUpload"/>
              </label>
            </div>
          </div>
        </div>
        <div class="popup-tpl-step2-footer">
          <div class="yellow-button">
            <button type="button" class="btn-default" @click.stop="goTo(step + 1)">{{
                __t('create-outfit.upload-button')
              }}
            </button>
          </div>
        </div>
      </div>
      <!-- Selected images -->
      <div class="popup-tpl-step2" v-else-if="step === 3">
        <div class="popup-tpl-step2-header">
          <div class="outfit-progress-bar"></div>
          <h1 class="h1-style">{{ __t('create-outfit.step2-title') }}</h1>
          <button type="button" class="btn back-button" @click.stop="goTo(step - 1)">{{
              __t('create-outfit.back-button')
            }}
          </button>
        </div>
        <div class="popup-tpl-step2-body">
          <div class="previewImages">
            <div v-for="(item, i) in uploadedImages.filesUrl" :key="'u-img-'+ i" class="imagePlaceholder">
              <!-- <span class="checker" v-text="i + 1"></span> -->
              <!-- <img :src="item" alt="" width="100%" /> -->
              <img v-if="item" :src="require('@/assets/images/general/image-placeholder.svg')" alt="" width="100%"/>
              <span v-if="item" class="clientImg">
                <img
                  :id="'upload-image-' + i"
                  :src="item"
                  alt=""
                  width="100%"
                  :style="{
                    'transform': 'translate('+ outfitData.images[i][2].position.x + 'px,' +
                    outfitData.images[i][2].position.y + 'px' +')'
                  }"/>
              </span>
            </div>
          </div>
        </div>
        <div class="popup-tpl-step2-footer">
          <div class="yellow-button">
            <button type="button" class="btn-default" @click.stop="goTo(step + 2)">
              {{ __t('create-outfit.next-button') }}
            </button>
            <button type="button" class="btn-default" @click.stop="goTo(step + 1)">
              {{ __t('create-outfit.add-sticker-button') }}
            </button>
          </div>
        </div>
      </div>
      <!-- Add sticker step -->
      <div class="popup-tpl-step4" v-else-if="step === 4">
        <div class="popup-tpl-step4-header">
          <div class="outfit-progress-bar"></div>
          <h1 class="h1-style">Outfit Pictures</h1>
          <button type="button" class="btn back-button" @click.stop="goTo(step - 1)"
                  v-text="__t('create-outfit.back-button')"/>
        </div>
        <div class="popup-tpl-step4-body">
          <div class="popup-tpl-step4-body-images">
            <template v-for="(item, i) in uploadedImages.filesUrl">
              <div
                :class="{
                  'active inUseActive': inUse.index === i,
                  'dropping': inUse.index === i && dragging
                }" :key="'u-img-'+ i" class="imagePlaceholder">
                <span class="checker" v-text="i + 1"></span>
                <span v-if="i !== inUse.index" class="clientImg" @click.stop="handleMarkups(i)">
                  <img :src="item" alt="" width="100%" :style="{
                    'transform': 'translate('+ outfitData.images[i][2].position.x + 'px,' +
                    outfitData.images[i][2].position.y + 'px' +')'
                  }"/>
                </span>
                <span v-else class="clientImg innerUseActive">
                  <img :src="item"
                       :id="'upload-image-' + i" alt="" width="100%" class="imageRotation"
                       :style="{
                    'transform': 'translate('+ outfitData.images[inUse.index][2].position.x + 'px,' +
                    outfitData.images[inUse.index][2].position.y + 'px' +')'
                  }"/>
                </span>
                <img width="100%" class="mainImage" :src="require('@/assets/images/general/image-placeholder.svg')"/>
                <template v-for="(av, n) in outfitData.images[i][1]">
                  <div v-if="av && inUse.index === i"
                       class="sticker"
                       :data-index="n"
                       :key="'c-av-key' + n"
                       :id="'sticker-id-'+ i + n"
                       :style="{
                    'width':'28%',
                    'left': av.position.x + 'px',
                    'top': av.position.y + 'px',
                  }">
                    <img :src="base.baseFilePath + av.path"/>
                  </div>
                </template>
                <div v-if="inUse.index === i" id="yes-drop" class="drop-object"></div>
              </div>
            </template>
          </div>
          <div class="popup-tpl-step4-body-choose">
            <div>
              <multiselect
                @select="handleMarkupSticker"
                :resetAfter="true"
                placeholder="Stickers"
                label="title"
                track-by="id"
                :options="base.stickers"
                :option-height="104"
                :show-labels="false">
                <!-- <template slot="singleLabel" slot-scope="props"><img class="option__image" :src="base.baseFilePath + props.option.image" alt=""></template> -->
                <template slot="option" slot-scope="props">
                  <img class="option__image" :src="base.baseFilePath + props.option.image_path" alt="">
                </template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="popup-tpl-step4-footer">
          <div class="yellow-button">
            <button type="submit" class="btn-default" @click="goTo(5)">{{ __t('create-outfit.next-button') }}</button>
            <!-- <button type="submit" class="btn-default">{{ __t('create-outfit.add-sticker-button') }}</button> -->
          </div>
        </div>
      </div>
      <!-- Outfit details -->
      <div class="popup-tpl-step5" v-else-if="step === 5">
        <div class="popup-tpl-step5-header">
          <div class="outfit-progress-bar"></div>
          <h1 class="h1-style">{{ __t('create-outfit.step3-title') }}</h1>
          <button type="button" class="btn back-button" @click.stop="goTo(step - 1)">{{
              __t('create-outfit.back-button')
            }}
          </button>
        </div>
        <div class="popup-tpl-step5-body">
          <form>
            <div class="form-group">
              <label for="title">{{ __t('create-outfit.occasion') }} <span>{{
                  __t('create-outfit.occasion-info')
                }}</span></label>
              <input type="text" v-model="outfitData.title" class="form-control" id="title"
                     aria-describedby="emailHelp">
            </div>
            <div class="form-group">
              <label for="message-box">{{ __t('create-outfit.description') }}
                <span>{{ __t('create-outfit.description-info') }}</span></label>
              <textarea name="subline" v-model="outfitData.description" id="message-box" rows="2"
                        class="form-control"></textarea>
            </div>
          </form>
        </div>
        <div class="popup-tpl-step5-footer">
          <div class="yellow-button">
            <button type="button" class="btn-default" :disabled="!outfitData.title || !outfitData.description"
                    @click.prevent="goTo(step + 1)">{{ __t('create-outfit.next-step-button') }}
            </button>
          </div>
        </div>
      </div>
      <div class="popup-tpl-step6" v-else-if="step === 6">
        <div class="popup-tpl-step6-header">
          <div class="outfit-progress-bar"></div>
          <h1 class="h1-style">{{ __t('create-outfit.step4-title') }}</h1>
          <button type="button" class="btn back-button" @click.stop="goTo(step - 1)">{{
              __t('create-outfit.back-button')
            }}
          </button>
        </div>
        <div class="popup-tpl-step6-body">
          <form>
            <div class="gender-side">
              <h4 class="h4-style">{{ __t('create-outfit.gender') }}</h4>
              <div class="form-group checkbox" v-for="(item, i) in base.outfitsGender" :key="'s-key-' + i">
                <input type="radio" v-model="outfitData.gender" name="gender" :id="'sex-id-' + i" :value="i">
                <label :for="'sex-id-' + i" v-text="item">Women</label>
              </div>
            </div>
            <div class="age-side">
              <h4 class="h4-style">{{ __t('create-outfit.age') }}</h4>
              <div class="form-group checkbox" v-for="(item, i) in base.ageRange" :key="'a-key-' + i">
                <input type="radio" v-model="outfitData.age_range" name="ageRange" :id="'age-id-'+ i" :value="i">
                <label :for="'age-id-' + i" v-text="item"></label>
              </div>
            </div>
          </form>
        </div>
        <div class="popup-tpl-step5-footer">
          <div class="yellow-button">
            <button type="button" class="btn-default"
                    :disabled="outfitData.age_range === null || outfitData.gender === null"
                    @click.stop="goTo(step + 1)">{{ __t('create-outfit.next-step-button') }}
            </button>
          </div>
        </div>
      </div>
      <div class="popup-tpl-step7" v-else-if="step === 7">
        <div class="popup-tpl-step5-header">
          <div class="outfit-progress-bar"></div>
          <h1 class="h1-style">{{ __t('create-outfit.step4-title') }}</h1>
          <button type="button" class="btn back-button" @click.stop="goTo(step - 1)">{{
              __t('create-outfit.back-button')
            }}
          </button>
        </div>
        <div class="popup-tpl-step7-body">
          <form>
            <div class="time-event-side">
              <h4 class="h4-style">{{ __t('create-outfit.time') }}</h4>
              <div class="checkboxes-group">
                <div class="form-group checkbox-button pb-3" v-for="(item, i) in base.time" :key="'time-key-' + i">
                  <input type="radio" name="time" :id="'time-id-' + i" v-model="outfitData.time" :value="i">
                  <label :for="'time-id-' + i" v-text="item"></label>
                </div>
              </div>
            </div>
            <div class="time-event-side">
              <h4 class="h4-style">{{ __t('create-outfit.styles') }}</h4>
              <form>
                <div class="checkboxes-group">
                  <div class="form-group checkbox-button custom-check-label"
                       :class="{'active': outfitData.outfit_styles.indexOf(item.id) !== -1}"
                       v-for="(item, i) in base.outfitStyles" :key="'dc-key-' + i">
                    <label @click.self="handleStyles(item.id)">
                      {{ item.name }}
                      <span @click="handleStyles(item.id)" class="remove"
                            v-if="outfitData.outfit_styles.indexOf(item.id) !== -1">
                        <img :src="require('@/assets/images/icons/close.svg')" alt="">
                      </span>
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div class="time-event-side">
              <h4 class="h4-style">{{ __t('create-outfit.dresscode') }}</h4>
              <form>
                <div class="checkboxes-group">
                  <div class="form-group checkbox-button pb-3" v-for="(item, i) in base.dressCodes"
                       :key="'dc-key-' + i">
                    <input type="radio" name="dress_code" :id="'dc-id-' + i" v-model="outfitData.dress_code"
                           :value="item.id">
                    <label :for="'dc-id-' + i" v-text="item.name"></label>
                  </div>
                </div>
              </form>
            </div>
            <div class="time-event-side">
              <h4 class="h4-style">{{ __t('create-outfit.step5-title') }}</h4>
              <form>
                <div class="checkboxes-group">
                  <div class="form-group checkbox-button custom-check-label"
                       :class="{'active': outfitData.event_types.indexOf(item.id) !== -1}"
                       v-for="(item, i) in base.eventTypes" :key="'et-key-' + i">
                    <label @click.self="handleEvents(item.id)">
                      {{ item.name }}
                      <span @click="handleEvents(item.id)" class="remove"
                            v-if="outfitData.event_types.indexOf(item.id) !== -1">
                        <img :src="require('@/assets/images/icons/close.svg')" alt="">
                      </span>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </form>
        </div>
        <div class="popup-tpl-step5-footer">
          <div class="yellow-button">
            <button v-if="lock" type="button" class="btn-default">Posting ...</button>
            <button v-else type="button" @click.stop="submitOutfit" class="btn-default"
                    :disabled="outfitData.time === null || outfitData.dress_code === null || outfitData.event_types.length < 1 ||  outfitData.outfit_styles.length < 1">
              POST
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import interact from 'interactjs'

export default {
  components: { Multiselect },
  data () {
    return {
      step: 1,
      lock: false,
      value: { img: '../../assets/images/icons/sticker-1.svg' },
      outfitData: {
        title: '',
        description: '',
        gender: null,
        age_range: null,
        time: null,
        dress_code: null,
        images: [],
        event_types: [],
        outfit_styles: [],
        canvas: {
          width: 0,
          height: 0
        }
      },
      uploadedImages: {
        filesUrl: [],
        files: [],
        index: [],
        forUpload: []
      },
      inUse: {
        index: null,
        stickers: []
      },
      dragging: false,
      dragIndex: null,
      resizedImages: []
    }
  },
  computed: {
    ...mapState('auth', ['base'])
  },
  methods: {
    ...mapActions('outfit', ['createOutfit', 'toggleCreate']),
    customLabel ({ title, desc }) {
      return `${title} – ${desc}`
    },
    goTo (step) {
      this.step = step
      switch (step) {
        case 1:
          // this.upload = false
          this.uploadedImages = {
            filesUrl: [],
            files: [],
            index: [],
            forUpload: []
          }
          break
        case 2:
          if (this.uploadedImages.filesUrl.length < 1) this.step = 1
          break
        case 3:
          // add stickers
          this.inUse = {
            index: 0,
            avatars: []
          }
          setTimeout(() => {
            this.handleImageSecondStep(3)
          }, 500)
          break
        case 4:
          this.inUse.index = 0
          this.resizedImages.push(this.inUse.index)
          setTimeout(() => {
            this.handleImageRotation()
          }, 500)
          break
      }
    },
    handleSingleUpload (e) {
      let index = e.target.id.split('-')[1]
      let files = e.target.files
      let realIndex = this.uploadedImages.filesUrl[index] ? index : this.uploadedImages.filesUrl.length
      let imgData = {
        angle: 0,
        scale: 1,
        position: {
          x: 0,
          y: 0
        },
        real: {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
          canvasWidth: 0,
          canvasHeight: 0
        }
      }

      if (files.length > 0) {
        for (let i = 0; i < (files.length < 4 ? files.length : 3); i++) {
          this.$set(this.outfitData.images, files.length < 2 ? realIndex : i, [files[i], [], imgData])
          this.$set(this.uploadedImages.filesUrl, files.length < 2 ? realIndex : i, URL.createObjectURL(files[i]))
          URL.revokeObjectURL(files[i])
          this.inUse.index = 0
        }
      }
      if (this.step < 2) {
        this.goTo(this.step + 1)
      }

      this.$nextTick(() => {
        this.inUse.index = files.length < 2 ? realIndex : 0
      })
    },
    handleMarkups (i) {
      this.inUse.index = i
      if (!this.resizedImages.includes(i)) {
        this.resizedImages.push(i)
        setTimeout(() => {
          this.handleImageSecondStep(4)
        }, 500)
      }
    },
    handleMarkupSticker (e) {
      let self = this

      let current = this.outfitData.images[this.inUse.index]
      let aindex = current[1].push({
        id: e.id,
        path: e.image_path,
        angle: 0,
        scale: 1,
        position: {
          x: 0,
          y: 0
        },
        real: {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        }
      })
      this.$set(this.outfitData.images, this.inUse.index, current)
      // debugger
      this.$nextTick(() => {
        let stickerEl = document.querySelector('#sticker-id-' + self.inUse.index + (aindex - 1))
        setTimeout(function () {
          self.outfitData.images[self.inUse.index][1][aindex - 1].real.width = stickerEl.getBoundingClientRect().width
          self.outfitData.images[self.inUse.index][1][aindex - 1].real.height = stickerEl.getBoundingClientRect().height
        }, 500)
        interact('.drop-object').dropzone({
          accept: '.sticker',
          overlap: 0.3,
          ondrop: function (event) {
            self.$nextTick(() => {
              self.outfitData.images[self.inUse.index][1][self.dragIndex] = null
            })
          },
          ondropdeactivate: function (event) {
            self.$nextTick(() => {
              self.dragging = false
            })
          }
        })
        interact('#sticker-id-' + self.inUse.index + (aindex - 1)).draggable({
          listeners: {
            start (event) {
              // debugger
              self.dragging = true
              event.target.classList.add('canDrag')
              self.dragIndex = parseInt(event.target.getAttribute('data-index'))
            },
            move (event) {
              // debugger
              self.outfitData.images[self.inUse.index][1][aindex - 1].position.x += event.dx
              self.outfitData.images[self.inUse.index][1][aindex - 1].position.y += event.dy

              self.outfitData.images[self.inUse.index][1][aindex - 1].real.x += event.dx
              self.outfitData.images[self.inUse.index][1][aindex - 1].real.y += event.dy
            },
            end (event) {
              event.target.classList.remove('canDrag')
            }
          }
        })
      })
    },
    handleImageRotation () {
      let self = this
      this.$nextTick(() => {
        interact('.imageRotation').draggable({
          onmove: function (event) {
            self.$set(self.outfitData.images[self.inUse.index], 2, {
              angle: self.outfitData.images[self.inUse.index][2].angle,
              scale: self.outfitData.images[self.inUse.index][2].scale,
              position: {
                x: self.outfitData.images[self.inUse.index][2].position.x + event.dx,
                y: self.outfitData.images[self.inUse.index][2].position.y + event.dy
              },
              real: {
                x: self.outfitData.images[self.inUse.index][2].position.x + event.dx,
                y: self.outfitData.images[self.inUse.index][2].position.y + event.dy,
                width: self.outfitData.canvas.width,
                height: self.outfitData.canvas.height,
                canvasWidth: self.outfitData.canvas.width,
                canvasHeight: self.outfitData.canvas.height
              }
            })
          }
        })
        this.handleImageSecondStep(4)
      })
    },
    handleImageSecondStep (step) {
      let parentBoundingRect = document.getElementsByClassName(step === 3 ? 'imagePlaceholder' : 'innerUseActive')[0].getBoundingClientRect()
      this.outfitData.canvas.width = parentBoundingRect.width
      this.outfitData.canvas.height = parentBoundingRect.height

      if (step === 3) {
        for (let i = 0; i < this.uploadedImages.filesUrl.length; i++) {
          let elImg = document.getElementById('upload-image-' + i)
          this.updatePosition(i, elImg, parentBoundingRect)
        }
      } else {
        let elImg = document.getElementById('upload-image-' + this.inUse.index)
        this.updatePosition(this.inUse.index, elImg, parentBoundingRect)
      }
    },
    updatePosition (index, ele, parent) {
      let customItem = JSON.parse(JSON.stringify(this.outfitData.images[index]))
      customItem[0] = this.outfitData.images[index][0]
      customItem[1] = this.outfitData.images[index][1]
      customItem[2].real = {
        width: ele.offsetWidth,
        height: ele.offsetHeight,
        canvasWidth: this.outfitData.canvas.width,
        canvasHeight: this.outfitData.canvas.height,
        y: ((parent.height - ele.offsetHeight) / 2),
        x: ((parent.width - ele.offsetWidth) / 2)
      }
      customItem[2].position = {
        y: (parent.height - ele.offsetHeight) / 2,
        x: (parent.width - ele.offsetWidth) / 2
      }
      this.$set(this.outfitData.images, index, customItem)
    },
    handleSelect (i) {
      let index = this.uploadedImages.index.indexOf(i)
      if (index !== -1) this.uploadedImages.index.splice(index, 1)
      else this.uploadedImages.index.push(i)
    },
    handleChecker (i) {
      let index = this.uploadedImages.index.indexOf(i)
      return index !== -1 ? (index + 1) : ''
    },
    handleEvents (id) {
      let index = this.outfitData.event_types.indexOf(id)
      if (index === -1) this.outfitData.event_types.push(id)
      else this.outfitData.event_types.splice(index, 1)
    },
    handleStyles (id) {
      let index = this.outfitData.outfit_styles.indexOf(id)
      if (index === -1) this.outfitData.outfit_styles.push(id)
      else this.outfitData.outfit_styles.splice(index, 1)
    },
    submitOutfit () {
      if (!this.lock) {
        this.lock = true
        let formData = new FormData()
        for (let item in this.outfitData) {
          switch (item) {
            case 'images':
              for (let i = 0; i < this.outfitData.images.length; i++) {
                formData.append('images[' + i + '][0]', this.outfitData.images[i][0])
                formData.append('images[' + i + '][1]', JSON.stringify(this.outfitData.images[i][1]))
                formData.append('images[' + i + '][2]', JSON.stringify(this.outfitData.images[i][2]))
              }
              break
            case 'outfit_styles':
            case 'event_types':
              for (let i = 0; i < this.outfitData[item].length; i++) {
                formData.append(item + '[' + i + ']', this.outfitData[item][i])
              }
              break
            default:
              formData.append(item, this.outfitData[item])
              break
          }
        }
        this.createOutfit(formData).then(resp => {
          this.toggleCreate(false)
          this.$store.dispatch('auth/searchUser')
          this.lock = false
          if (this.$route.name === 'Profile') {
            this.$store.dispatch('outfit/getRatedOutfits')
          }
        }).catch(() => {
          this.lock = false
        })
      }
    }
  }
}
</script>
