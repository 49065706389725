import Vue from 'vue'
import Vuex from 'vuex'

import persistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import translations from './modules/translations'

// import authStore from './modules/auth'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    auth,
    translations
  },
  plugins: [persistedState()]
})
