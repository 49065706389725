import { Http } from '@/helpers/http-helper'
import { store } from '@/store'
const notifications = JSON.parse(localStorage.getItem('notifications'))
const state = {
  notifications: notifications || null
}
const getters = {
  getNotifications: state => state.notifications
}
const mutations = {
  setNotifications (state, data) {
    state.notifications = data
    localStorage.setItem('notifications', JSON.stringify(state.notifications))
  }
}
const actions = {
  searchNotifications ({ commit }) {
    return Http.get('notifications').then(resp => {
      resp.data && commit('setNotifications', resp.data)
      return resp.data
    }).catch(err => {
      return Promise.reject(err)
    })
  },
  markRead ({ commit }) {
    return Http.put('notifications/read').then(resp => {
      store.commit('auth/setNotify', false, { root: true })
      return resp.data
    }).catch(err => {
      return Promise.reject(err)
    })
  },
  getPage ({ commit }, data) {
    return Http.get('pages/' + data).then(resp => {
      return resp.data
    }).catch(e => {
      return Promise.reject(e)
    })
  },
  searchUser ({ commit }) {
    return Http.get('users/get-current-user').then(resp => {
      commit('setUser', resp.data)
      return resp.data
    }).catch(err => {
      Promise.reject(err)
    })
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
