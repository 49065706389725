import { Http, AuthHttp } from '@/helpers/http-helper'

const state = {
  token: {},
  user: null,
  base: null,
  addApp: false
}

const getters = {
  getToken: state => state.token,
  getUser: state => state.user,
  getBase: state => state.base
}

const mutations = {
  setToken (state, token) {
    state.token = token
  },
  setBase (state, data) {
    state.base = data
  },
  setUser (state, data) {
    state.user = data
  },
  removeToken (state) {
    state.token = {}
  },
  setNotify (state, data) {
    state.base.hasNotifications = data
  },
  addAppViewed (state) {
    state.addApp = true
  }
}

const actions = {
  login ({ commit }, user) {
    return AuthHttp.post('users/login', user).then(resp => {
      const data = {
        expire_at: resp.data.expire_at,
        token: resp.data.token
      }
      commit('setUser', resp.data.user)
      commit('setToken', data)
      return resp.data
    }).catch(error => {
      return Promise.reject(error)
    })
  },
  logout ({ commit }) {
    return Http.post('users/logout').then(response => {
      commit('removeToken')
      return response.data
    }).catch(error => {
      return Promise.reject(error)
    })
  },
  register ({ commit }, user) {
    return AuthHttp.post('users/register', user).then(resp => {
      return resp.data
    }).catch((err) => {
      return err.response.data
    })
  },
  forgotPassword ({ commit }, data) {
    return AuthHttp.post('users/forgot-password', data).then(resp => {
      return resp.data
    }).catch(error => {
      return Promise.reject(error)
    })
  },
  resetPassword ({ commit }, data) {
    return Http.post('users/reset-password', data).then(resp => {
      return resp.data
    }).catch(error => {
      return error.response.data
    })
  },
  searchBase ({ commit }) {
    return Http.get('config/init').then((resp) => {
      commit('setBase', resp.data)
      return resp.data
    }).catch(error => {
      return Promise.reject(error)
    })
  },
  updateProfileImage ({ commit }, data) {
    return Http.post('users/profile-image', data, {
      headers: { 'Content-Type': 'image/*' }
    }).then(resp => {
      commit('setUser', resp.data)
      return resp.data
    }).catch(error => {
      return error.response.data
    })
  },
  updateProfileFacebook ({ commit }, data) {
    return Http.post('users/profile-facebook', data).then(resp => {
      commit('setUser', resp.data)
      return resp.data
    }).catch(error => {
      return error.response.data
    })
  },
  updateAvatar ({ commit }, avatar) {
    return Http.post('users/profile-avatar', avatar).then(resp => {
      commit('setUser', resp.data)
      return resp.data
    }).catch(error => {
      return error.response.data
    })
  },
  updateProfile ({ commit }, data) {
    return Http.post('users', data).then(resp => {
      commit('setUser', resp.data)
      return resp.data
    }).catch(error => {
      return error.response.data
    })
  },
  getQuiz ({ commit }, data) {
    return Http.get('quiz-steps', { params: data }).then(resp => {
      return resp.data
    }).catch(error => {
      return error.response.data
    })
  },
  updateQuiz ({ commit }, data) {
    return Http.post('users/quiz-data', data).then(resp => {
      commit('setUser', resp.data)
      return resp.data
    }).catch(error => {
      return error.response.data
    })
  },
  searchUser ({ commit }) {
    return Http.get('users/get-current-user').then(resp => {
      commit('setUser', resp.data)
      return resp.data
    }).catch(err => {
      return err.response.data
    })
  },
  getVerificationCode ({ commit }, data) {
    return Http.post('users/get-verification-code', data).then(resp => {
      return resp.data
    }).catch(err => {
      return err.response.data
    })
  },
  updateUser ({ dispatch }, data) {
    return Http.put('users/update', data).then(resp => {
      dispatch('searchUser')
      return resp
    }).catch(err => {
      return err.response
    })
  },
  updateUserSettings ({ commit }, data) {
    return Http.put('users/update-settings', data).then(resp => {
      commit('setUser', resp.data.result)
      return resp.data
    }).catch(err => {
      return err.response.data
    })
  },
  saveToken ({ commit }, data) {
    return Http.post('notifications/add-token', data).then(resp => {
      return resp.data
    }).catch(err => Promise.reject(err))
  },
  markSeenBadge ({ commit }) {
    return Http.get('users/has-seen-badge').then(resp => {
      commit('setUser', resp.data)
      return resp.data
    }).catch(err => Promise.reject(err))
  },
  emailVerify ({ commit }, data) {
    return AuthHttp.post('users/verify', data).then(resp => {
      return resp.data
    }).catch(err => {
      return err.response.data
    })
  },
  resendVerification ({ commit }, data) {
    return AuthHttp.post('users/resend-verification-code', data).then(resp => {
      return resp
    }).catch(err => {
      return err.response.data
    })
  },
  deleteAccount ({ commit }) {
    return Http.delete('users').then(resp => {
      commit('removeToken')
      return resp.data
    }).catch(err => {
      Promise.reject(err)
    })
  },
  fbAuthUlr ({ commit }) {
    return AuthHttp.get('users/facebook/redirect').then(resp => {
      return resp.data
    }).catch(err => {
      return Promise.reject(err)
    })
  },
  fbLogin ({ commit }, data) {
    return AuthHttp.get('users/facebook/callback', {
      params: {
        code: data
      }
    }).then(resp => {
      // commit('setUser', resp.data)
      const data = {
        expire_at: resp.data.expire_at,
        token: resp.data.token
      }
      commit('setUser', resp.data.user)
      commit('setToken', data)
      return resp.data
    }).catch(err => {
      return Promise.reject(err)
    })
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
