<template>
  <div class="sign-up-wrap purple-bg py-13 upload">
    <span class="stepProgress last"></span>
    <div class="small-container">
      <div class="sign-up-wrap-ms-form upload-wrapper">
        <profile-upload :isProfile="false"></profile-upload>
      </div>
    </div>
    <PopupWhite :content="popupW" v-if="hide" @handlePopup="handleMyPopup"></PopupWhite>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PopupWhite from '@/components/modals/PopupWhite.vue'
import ProfileUpload from '@/components/profile/ProfileUpload'
const smallDevice = window.matchMedia('(max-width: 767px)')
export default {
  components: {
    PopupWhite,
    ProfileUpload
  },
  data () {
    return {
      hide: false,
      valid: false,
      showAvatars: false,
      popupW: {
        title: 'Rating needed',
        text: 'X minutes left to rate USERNAME The community wants your opinion!',
        buttons: {
          text: 'Let’s do it'
        }
      },
      error: {
        show: false,
        msg: ''
      },
      profilePicture: null
    }
  },
  computed: {
    ...mapState('auth', ['avatars', 'base']),
    isMobile () {
      return smallDevice.matches
    }
  },
  methods: {
    ...mapActions('auth', ['updateAvatar', 'updateProfileImage']),
    handleMyPopup (val) {
      this.hide = val
    }
  }
}
</script>
