import { Http } from '@/helpers/http-helper'
// import { store } from '@/store'
// const waitingOutfits = JSON.parse(localStorage.getItem('waiting'))
const ratedOutfits = JSON.parse(localStorage.getItem('rated'))
const state = {
  waiting: [],
  rated: ratedOutfits || [],
  create: false,
  feed: null,
  canRate: false,
  canCreate: true
}
const getters = {
  getWaiting: state => state.waiting,
  getRated: state => state.rated,
  getCreate: state => state.create,
  getFeed: state => state.feed
}
const mutations = {
  setWaiting (state, data) {
    state.waiting = data
  },
  setRated (state, data) {
    state.rated = data
    localStorage.setItem('rated', JSON.stringify(state.rated))
  },
  setCreate (state, data) {
    state.create = data
  },
  setCanCreate (state, data) {
    state.canCreate = data
  },
  setCanRate (state, data) {
    state.canRate = data
  },
  setFeed (state, data) {
    state.feed = data
  }
}
const actions = {
  getRatedOutfits ({ commit }, data) {
    return Http.get('outfits', data && {
      params: {
        sort: data,
        page: 0
      }
    }).then(resp => {
      resp.data.rated && commit('setRated', resp.data.rated)
      resp.data.waiting && commit('setWaiting', resp.data.waiting)
      return resp.data
    }).catch(error => {
      Promise.reject(error)
    })
  },
  createOutfit ({ commit }, data) {
    return Http.post('outfits/create', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(resp => {
      return resp.data
    }).catch(err => {
      Promise.reject(err)
    })
  },
  checkCanCreate ({ commit }, data) {
    if (data !== undefined) {
      commit('setCanCreate', true)
    } else {
      return Http.get('outfits/can-create').then(resp => {
        commit('setCanCreate', resp.data.result)
        return resp.data.result
      }).catch(err => Promise.reject(err))
    }
  },
  toggleCreate ({ commit, dispatch }, data) {
    if (data) dispatch('checkCanCreate').then(resp => commit('setCreate', resp))
    else commit('setCreate', data)
  },
  searchFeed ({ commit }) {
    return Http.get('outfits/feed').then(resp => {
      commit('setFeed', Array.isArray(resp.data) === false ? resp.data : null)
      return Array.isArray(resp.data) === false ? resp.data : false
    }).catch(error => {
      commit('setFeed', null)
      return error.response.data
    })
  },
  deleteOutfitItem ({ commit }, id) {
    return Http.delete('outfits/delete-outfit-item/' + id).then(resp => {
      return resp.data
    }).catch(err => {
      return err.response.data
    })
  },
  rateFeed ({ commit, dispatch }, data) {
    return Http.post('ratings/add-rating', data).then(resp => {
      commit('setFeed', resp.data)
      return resp.data
    }).catch(err => Promise.reject(err))
  },
  canRate ({ commit }) {
    return Http.get('api/outfits/can-rate').then(resp => {
    })
  },
  hideFeed ({ commit }, data) {
    return Http.post('outfits/hide', data).then(resp => {
      return resp.data
    }).catch(err => Promise.reject(err))
  },
  reportFeed ({ commit }, data) {
    return Http.post('outfits/report', data).then(resp => {
      return resp.data
    }).catch(err => Promise.reject(err))
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
