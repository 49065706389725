import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import VueGtag from 'vue-gtag'
import visibility from 'vue-visibility-change'
import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import '@/assets/scss/style.scss'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './registerServiceWorker'

Vue.use(VueCookieBot, {
  cookieBotID: '8e6b3c4d-578d-4bf1-9eb5-865f7495e6ca'
})

Vue.use(VueGtag, {
  config: {
    id: 'G-0J9PRXLFWJ'
  }
})

Vue.use(visibility)

Vue.use(require('vue-pusher'), {
  api_key: 'ef323c22f8b75d887da3',
  options: {
    cluster: 'eu',
    encrypted: true
  }
})

Sentry.init({
  Vue,
  dsn: 'https://2d6417fb22c746bf89237181d5f81cb3@o1303371.ingest.sentry.io/6542284',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'wearitoutapp.com', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

Vue.prototype.__t = function (key) {
  if (parseInt(this.$route.query.copies) === 1) {
    return key
  } else if (this.$store.state.translations.labels && this.$store.state.translations.labels[key]) {
    return this.$store.state.translations.labels[key]
  }
  return key
}

store.dispatch('translations/loadTranslations')
// if (store.state.auth.token.token) {
//   store.dispatch('auth/searchBase')
// }

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
