<template>
  <footer>
    <div class="footer-wrapper">
      <div class="footer-wrapper-top">
        <ul>
          <li v-for="page in pages" :key="'page-key' + page.id">
            <router-link :to="{ path: page.slug }" v-text="page.name"></router-link>
          </li>
        </ul>
      </div>
      <div class="footer-wrapper-bottom">
        <h5 class="h5-style-lg" v-html="copyright"></h5>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      pages: [
        {
          name: 'About',
          id: 1,
          slug: 'about'
        },
        {
          name: 'Help',
          id: 2,
          slug: 'help'
        },
        {
          name: 'Privacy',
          id: 3,
          slug: 'privacy'
        },
        {
          name: 'Terms',
          id: 4,
          slug: 'terms'
        }
      ],
      copyright: this.__t('general.copyright')
    }
  }
}
</script>
