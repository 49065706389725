<template>
  <div class="medium-container theFeed">
    <div class="feed-wrapper-in-wrap">
      <div class="feed-wrapper-in-wrap-head">
        <div class="feed-wrapper-in-wrap-head-profile">
          <div class="feed-wrapper-in-head-profile-img">
            <img :src="feed.user.profile_image" width="100%" alt=""/>
          </div>
          <div class="feed-wrapper-in-wrap-head-profile-content">
            <h5 class="h5-style-lg" v-text="feed.user.username"></h5>
            <span v-text="feed.user.subline"></span>
          </div>
        </div>
        <div class="feed-wrapper-in-wrap-head-settings">
          <button class="btn" @click="more = !more">
            <img :src="require('@/assets/images/icons/dots.svg')" width="100%" alt=""/>
          </button>
          <div class="window-feed" v-if="more">
            <ul>
              <li><a class="report" @click.prevent="$emit('handleMore', 0); more = false">{{ __t('feed.report') }}</a>
              </li>
              <li><a class="hide" @click.prevent="$emit('handleMore', 1); more = false">{{ __t('feed.hide') }}</a></li>
              <li><a class="go-back" @click.prevent="more = false">{{ __t('feed.go-back-button') }}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="feed-wrapper-in-wrap-body">
        <div class="feed-wrapper-in-wrap-body-img">
          <swiper ref="feedSlider" class="swiper noSwipingClass" :options="sliderOptions" @slideChange="slideChange">
            <swiper-slide v-for="(item, i) in feed.outfit_items" :key="'feed-s-key-' + i">
              <div @click="handleNextSlide(i)">
                <img :src="$store.state.auth.base.baseFilePath + item.image_path" width="100%" alt=""/>
                <icon-rate
                  v-if="item && item.hasRating"
                  :color="(item.currentUserRating == 5 ? 'FFFD0B' : (item.currentUserRating == 3 ? '8ED6F4' : 'FFB7F8'))"
                  :type="(item.currentUserRating == 5 ? 'yes' : (item.currentUserRating  == 3 ? 'maybe' : 'no'))"></icon-rate>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="feed-wrapper-in-wrap-body-stickers">
          <template v-for="(item, i) in $store.state.auth.base.outfitItemRatings">
            <a :key="'ratings-key-' + i"
               :class="(i == 5 ? 'yes' : (i == 3 ? 'maybe' : 'no'))"
               @click.prevent="$emit('handleRate', item.value, feed.outfit_items[currentIndex].id)">
              <icon-rate
                v-if="feed.outfit_items[currentIndex] && !feed.outfit_items[currentIndex].hasRating"
                :color="'211F2F'"
                :type="(item.value == 5 ? 'yes' : (item.value == 3 ? 'maybe' : 'no'))"></icon-rate>
            </a>
          </template>
        </div>
      </div>
      <div class="feed-wrapper-in-wrap-footer">
        <div class="feed-wrapper-in-wrap-footer-left">
          <div class="feed-wrapper-in-wrap-footer-left-title">
            <h5 class="h5-style" v-text="feed.title"></h5>
            <div class="time">
              <span v-if="feed.outfit_items[currentIndex] && !feed.outfit_items[currentIndex].hasRating"
                    v-text="handlePending(feed.deadline) + ' min left'"></span>
              <span v-else class="rated" v-text="' ' + __t('feed.rated')"></span>
            </div>
          </div>
          <div class="feed-wrapper-in-wrap-footer-left-content">
            <p v-text="feed.description"></p>
            <div class="tags">
              <a v-if="$store.state.auth.base.time[feed.time]" v-text="$store.state.auth.base.time[feed.time]"
                 class="tag"></a>
              <a v-for="(item, i) in feed.outfit_styles" :key="'feed-os-key-' + i" v-text="item.name" class="tag"></a>
              <a v-for="(item, i) in feed.dress_codes" :key="'feed-dc-key-' + i" v-text="item.name" class="tag"></a>
              <a v-for="(item, i) in feed.event_types" :key="'feed-ev-key-' + i" v-text="item.name" class="tag"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import IconRate from '@/components/icons/icon-rate'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  props: {
    feed: {
      type: Object,
      default: null,
      required: true
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    IconRate
  },
  data () {
    return {
      more: false,
      currentIndex: 0,
      sliderOptions: {
        slidesPerView: 'auto',
        autoHeight: true,
        // allowTouchMove: false,
        // slideToClickedSlide: true,
        watchSlidesProgress: true,
        ovbserver: true,
        observeSlideChildren: true,
        observeParent: true,
        loop: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          renderBullet (index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom">${index + 1}</span>`
          }
        }
      },
      pendingInterval: null
    }
  },
  computed: {
    swiper () {
      return this.$refs.feedSlider.$swiper
    }
  },
  methods: {
    handlePending (datetime) {
      let newDate = new Date()
      let current = new Date(datetime)
      let timing = Math.round(((current.getTime() - newDate.getTime()) / 1000) / 60)
      if (timing < 1) {
        timing = 1
      }
      return timing
    },
    handleItemRate (index) {
      // let item = this.feed.outfit_items[this.currentIndex]
      this.$emit('handleRating', index, this.feed.outfit_items[this.currentIndex])
    },
    slideChange () {
      this.currentIndex = this.swiper.realIndex
    },
    handleNextSlide (i) {
      !this.swiper.isEnd ? this.swiper.slideNext() : this.swiper.slideTo(0)
      // this.swiper.isEnd ? this.swiper.slidePrev() : (this.swiper.isBeginning ? this.swiper.this.swiper.slideNext() : '')
    }
  },
  watch: {
    feed (nv, ov) {
      nv.id !== ov.id && this.swiper.slideTo(0)
      this.swiper.update()
    }
  },
  created () {
    this.handlePending(this.feed.deadline) < 0 && this.$emit('handleNewFeed')
  },
  mounted () {
    this.pendingInterval = setInterval(() => {
      this.handlePending(this.feed.deadline)
    }, 45000)
  },
  beforeDestroy () {
    clearInterval(this.pendingInterval)
  }
}
</script>
