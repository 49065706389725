<template>
  <div class="accordion-item">
    <div class="accordion-item-head" @click="toggleItem(item)">
      <h3 v-html="item.title"></h3>
    </div>
    <transition name="slide">
      <div v-if="isActive === item.slug" class="item-active">
        <div class="accordion-item-body" v-html="content">
          <!-- <h5 v-html="accprop.content"></h5> -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    item: {
      type: Object,
      default: null,
      required: true
    },
    // index: {
    //   type: Number,
    //   default: null,
    //   required: true
    // },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isActive: null,
      content: null
    }
  },
  methods: {
    toggleItem (item) {
      if (!this.isMobile) {
        // eslint-disable-next-line
        if (this.isActive != item.slug) {
          this.$store.dispatch('user/getPage', item.slug).then(resp => {
            this.content = resp.body_text

            setTimeout(function () {
              document.querySelector('[href="opencookiebot"]').addEventListener('click', function (e) {
                e.preventDefault()
                e.stopPropagation()

                window.Cookiebot.show()
              })
            })
          })
          this.isActive = item.slug
        } else this.isActive = null
      } else this.$router.push({ name: 'Page', params: { slug: item.slug } })
    }
  }
}
</script>
